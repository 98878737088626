import Select from "../../../../components/common/Select";

import TextInput from "../../../../components/common/TextInput";
import DiscardSaveButtons from "../../../../components/tv-app/discard-save-buttons";
import TextArea from "../../../../components/common/TextArea";
import FileInput from "../../../../components/common/FileInput";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {
  cleanObject,
  errorToast,
  objectToFormData,
  successToast,
} from "../../../../utils/helpers";
import useGetCouponTypesQuery from "../../../../hooks/use-get-coupon-types";
import useGetCostCentersDataQuery from "../../../../hooks/use-get-cost-centers-data";
import useGetEligibilityCriteriaQuery from "../../../../hooks/use-get-eligibility-criteria";
import useGetBusinessReasonsDataQuery from "../../../../hooks/use-get-business-reasons";
import { queryClient } from "../../../../App";
import {
  AddEligibilitySvg,
  LeftArrowSvg,
  RemoveEligibilitySvg,
} from "../../../../icons";
import { useEffect, useState } from "react";
import ImageCropModal from "../../../../components/common/CropImage";
import { IOffer } from "../../../../utils/types";
import useUpdateOffer from "../../../../hooks/use-update-offer";
import Spinner from "../../../../components/common/Spinner";
import DynamicMultiSelect from "../../../../components/common/DynamicMultiSelect";
import { useNavigate } from "react-router-dom";
import useAddOfferTemplate from "../../../../hooks/use-add-offer-template";

type SelectValue = { label?: string; value?: string } | null | undefined;

interface ICreateOfferFormValues {
  id?: string;
  costCenter: SelectValue;
  costSubCenter?: SelectValue;
  name: string;
  description: string;
  redemptionDescription: string;
  businessReason: SelectValue;
  redemptionLocation: SelectValue;
  tvSetupImage: Blob | string | null;
  type: SelectValue;
  value?: number | null;
  cost?: number | null;
  eligibilityCriterias: {
    eligibilityCriteria: {
      label: string;
      value: string;
    } | null;
    data:
      | { label: string; value: string }
      | { label: string; value: string }[]
      | string
      | undefined;
  }[];
}

const RESERVATION_NO = "Reservation No";
const DynamicMultiSelectKeys = ["RESERVATION_NO"];

const CreateOfferTemplate = ({
  offer = {},
  isEditOffer = false,
  onSuccess,
}: {
  offer?: Partial<IOffer>;
  isEditOffer: boolean;
  onSuccess?: () => void;
}) => {
  const navigate = useNavigate();
  const { data: costCentersData, isLoading: costCenterLoading } =
    useGetCostCentersDataQuery();
  const { data: couponTypesData, isLoading: couponTypesDataLoading } =
    useGetCouponTypesQuery();
  const {
    data: eligibilityCriteriaData,
    isLoading: eligibilityCriteriaLoading,
  } = useGetEligibilityCriteriaQuery();
  const { data: businessReasonData, isLoading: businessReasonDataLoading } =
    useGetBusinessReasonsDataQuery();

  const [disableEligibilityFieldsAddOn] = useState<string[]>([RESERVATION_NO]);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);

  const [defaultValues, setDefaultValues] = useState<ICreateOfferFormValues>({
    costCenter: offer?.costCenter
      ? {
          label: costCentersData?.result?.data.find(
            (x) => x._id === offer?.costCenter
          )?.name,
          value: offer?.costCenter,
        }
      : null,
    costSubCenter: null,
    name: offer?.name || "",
    description: offer?.description || "",
    redemptionDescription: offer?.redemptionDescription || "",
    businessReason: null,
    redemptionLocation: null,
    tvSetupImage: offer?.tvSetup?.image as string,
    type: offer?.type
      ? {
          label: couponTypesData?.result?.data.find(
            (x) => x._id === offer?.type
          )?.name,
          value: offer.type,
        }
      : null,
    value: offer?.value || null,
    cost: offer?.cost !== undefined ? offer.cost : null,
    eligibilityCriterias: [{ eligibilityCriteria: null, data: "" }],
  });

  const { mutate: addOfferTemplate, isPending: loading } =
    useAddOfferTemplate();
  const { mutate: updateOffer, isPending: updateLoading } = useUpdateOffer({
    id: offer._id,
  });

  const clearValues = () => {
    methods.reset({ ...defaultValues });
  };
  const onSubmit = (data: ICreateOfferFormValues) => {
    let payload = {
      ...data,
      hotelId: "c102",
      type: offer.type || data.type?.value,
      businessReason: data.businessReason?.value,
      costCenter: data.costCenter?.value,
      costSubCenter: data.costSubCenter?.value,
      redemptionLocation: data.redemptionLocation?.value,
      cost: data.cost,
      value: offer.value || data.value,
      eligibilityCriterias: data.eligibilityCriterias.map((x) => ({
        criteriaId: x.eligibilityCriteria?.value,
        ...(x.data
          ? {
              values: Array.isArray(x.data)
                ? x.data.map((y: any) => y.value)
                : typeof x.data === "string"
                ? [x.data]
                : [(x.data as SelectValue)?.value],
            }
          : {}),
      })),
    };

    cleanObject(payload);
    if (isEditOffer) {
      updateOffer(objectToFormData(payload), {
        onSuccess: async (res) => {
          successToast(res.result.message);
          handleImageClear();
          onSuccess?.();
        },
        onError: (err) => {
          errorToast(err?.response?.data?.result?.message);
        },
      });
    } else {
      // return;
      addOfferTemplate(objectToFormData(payload), {
        onSuccess: async (res) => {
          successToast(res.result.message);
          await queryClient.refetchQueries({ queryKey: ["offer-template"] });
          handleImageClear();
          const fileInput = document.getElementById("tv-setup-image");
          if (fileInput) {
            (fileInput as HTMLInputElement).value = "";
          }
          clearValues();
        },
        onError: (err) => {
          errorToast(err?.response?.data?.result?.message);
        },
      });
    }
  };

  const methods = useForm<ICreateOfferFormValues>({
    defaultValues,
  });
  const {
    fields: eligibilityCriteriaFields,
    append: appendEligibilityCriteria,
    remove: removeEligibilityCriteria,
  } = useFieldArray({
    control: methods.control,
    name: "eligibilityCriterias", // unique name for your Field Array
  });
  const { setValue, watch, handleSubmit } = methods;
  const {
    id,
    costCenter,
    type,
    value,
    cost,
    eligibilityCriterias,
    tvSetupImage,
  } = watch();

  const costCenterOptions =
    costCentersData?.result?.data.map((x) => ({
      label: x.name,
      value: x._id,
    })) || [];

  const subCentersOptions =
    costCentersData?.result?.data
      ?.find((x) => x._id === costCenter?.value)
      ?.subCenters?.map((x) => ({
        label: x.name,
        value: x._id,
      })) || [];

  const redemptionLocationOptions =
    costCentersData?.result?.data
      .map((x) => x?.subCenters || [])
      .flat()
      ?.map((x) => ({
        label: x.name,
        value: x._id,
      })) || [];

  const offerTypeOptions =
    couponTypesData?.result?.data.map((x) => ({
      label: x.name,
      value: x._id,
    })) || [];

  const selectedCouponType = couponTypesData?.result?.data.find(
    (x) => x._id === type?.value
  );

  const [valueType, setValueType] = useState<string | undefined>(
    selectedCouponType?.valueType
  );

  useEffect(() => {
    if (selectedCouponType) {
      setValueType(selectedCouponType.valueType);
    }
  }, [selectedCouponType]);

  const eligibilityOptions =
    eligibilityCriteriaData?.result?.data
      .sort((b, a) => b.viewRank - a.viewRank)
      .map((x) => ({
        label: x.name,
        value: x._id,
      })) || [];

  const [dynamicOptionsReset, setdynamicOptionsReset] = useState(true);

  const handleDynamicOptionsReset = () => {
    setdynamicOptionsReset(false);
  };

  const businessReasonOptions =
    businessReasonData?.result?.data.map((x) => ({
      label: x.name,
      value: x._id,
    })) || [];

  useEffect(() => {
    if (
      !costCenterLoading &&
      !couponTypesDataLoading &&
      !eligibilityCriteriaLoading &&
      !businessReasonDataLoading &&
      isEditOffer
    ) {
      let eligibilityFormData: {
        eligibilityCriteria: {
          label: string;
          value: string;
        } | null;
        data:
          | { label: string; value: string }
          | string
          | undefined
          | { label: string; value: string }[];
      }[] = [];
      offer.eligibilityCriterias?.map((eligibility) => {
        const selectedEligibility = eligibilityCriteriaData?.result?.data.find(
          (x) => x._id === eligibility.criteriaId
        );

        const valueTypeEligibility = DynamicMultiSelectKeys.includes(
          selectedEligibility?.key!
        )
          ? "DYNAMIC_MULTI_SELECT"
          : selectedEligibility?.valueType;

        if (
          selectedEligibility &&
          valueTypeEligibility === "DYNAMIC_MULTI_SELECT"
        ) {
          const values = eligibility.values.map((value: any) => ({
            label: value,
            value: value,
          }));
          eligibilityFormData.push({
            eligibilityCriteria: {
              label: selectedEligibility?.name,
              value: selectedEligibility?._id,
            },
            data: values,
          });
        } else if (
          selectedEligibility &&
          valueTypeEligibility === "SINGLE_SELECT"
        ) {
          const values = {
            label: selectedEligibility?.options?.find(
              (x) => x.value === eligibility.values[0]
            )?.label,
            value: eligibility.values[0],
          };

          eligibilityFormData.push({
            eligibilityCriteria: {
              label: selectedEligibility?.name,
              value: selectedEligibility?._id,
            },
            data: values,
          });
        } else if (
          selectedEligibility &&
          valueTypeEligibility === "MULTI_SELECT"
        ) {
          const values = selectedEligibility?.options
            ?.filter((x) => eligibility.values.includes(x.value))
            .map((x) => ({ label: x.label, value: x.value }));
          eligibilityFormData.push({
            eligibilityCriteria: {
              label: selectedEligibility?.name,
              value: selectedEligibility?._id,
            },
            data: values,
          });
        } else if (selectedEligibility && valueTypeEligibility === "TEXT") {
          const values = eligibility.values[0];
          eligibilityFormData.push({
            eligibilityCriteria: {
              label: selectedEligibility?.name,
              value: selectedEligibility?._id,
            },
            data: values,
          });
        } else if (selectedEligibility && valueTypeEligibility === "NONE") {
          eligibilityFormData.push({
            eligibilityCriteria: {
              label: selectedEligibility?.name,
              value: selectedEligibility?._id,
            },
            data: undefined,
          });
        }
      });

      const defaultFormValuesAfterFetch = {
        costCenter: offer?.costCenter
          ? {
              label: costCentersData?.result?.data.find(
                (x) => x._id === offer?.costCenter
              )?.name,
              value: offer?.costCenter,
            }
          : null,
        costSubCenter: offer?.costSubCenter
          ? subCentersOptions.find((x) => x.value === offer?.costSubCenter)
          : null,
        name: offer?.name || "",
        description: offer?.description || "",
        redemptionDescription: offer?.redemptionDescription || "",
        businessReason: offer?.businessReason
          ? businessReasonOptions.find((x) => x.value === offer?.businessReason)
          : null,
        redemptionLocation: offer?.redemptionLocation
          ? subCentersOptions.find((x) => x.value === offer?.redemptionLocation)
          : null,
        tvSetupImage: offer?.tvSetup?.image || null,
        type: offer.type
          ? offerTypeOptions.find((x) => x.value === offer.type)
          : null,
        value: offer?.value || null,
        cost: offer?.cost || null,
        eligibilityCriterias: eligibilityFormData,
      };
      setDefaultValues(defaultFormValuesAfterFetch);
      methods.reset(defaultFormValuesAfterFetch);
    }
  }, [
    costCenterLoading,
    couponTypesDataLoading,
    eligibilityCriteriaLoading,
    businessReasonDataLoading,
    isEditOffer,
  ]);

  const eligibilityCriteriaAppendField = () => {
    appendEligibilityCriteria({
      eligibilityCriteria: null,
      data: "",
    });
  };

  const eligibilityCriteriarRemoveField = (index: number) => {
    removeEligibilityCriteria(index);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      setImageFile(file);
      setIsCropModalOpen(true);
    }
  };

  const handleCropComplete = (croppedImageBlob: Blob | null) => {
    if (croppedImageBlob) {
      const file = new File([croppedImageBlob], imageFile?.name!, {
        type: croppedImageBlob?.type,
        lastModified: imageFile?.lastModified,
      });
      setValue("tvSetupImage", file);
    }
    setIsCropModalOpen(false);
  };

  const handleImageClear = () => {
    setImageFile(null);
    setValue("tvSetupImage", null);
  };

  const CouponTypeKEY = selectedCouponType?.key;

  return (
    <FormProvider {...methods}>
      {costCenterLoading ||
      couponTypesDataLoading ||
      eligibilityCriteriaLoading ||
      businessReasonDataLoading ? (
        <Spinner fullScreenOverlay className="z-[100]" />
      ) : null}
      <div>
        <div className="flex gap-2 items-center">
          <div onClick={() => navigate(-1)} className="cursor-pointer">
            <LeftArrowSvg />
          </div>
          <h3 className="text-[22px] font-normal text-[#1E2F65]">
            Create Offer template
          </h3>
        </div>
        <div className="grid grid-cols-1 relative mt-5">
          <TextInput
            name="name"
            rules={{
              required: "Template Name is required",
              maxLength: {
                value: 30,
                message: "Template Name cannot exceed 30 characters",
              },
            }}
            label="Template Name"
            placeholder="$20 F&B Credit on all Outlets"
          />
        </div>
        <div className="mt-5">
          <TextArea
            name="description"
            rules={{
              required: "Template Description is required",
              maxLength: {
                value: 110,
                message: "Template Description cannot exceed 110 characters",
              },
            }}
            label="Template Description"
            placeholder="Enjoy a $20 Food & Beverage credit at all outlets! Plus, get a free drink exclusively when using Shashi Cash. 
        Don't miss out on this special offer!"
            className="w-full h-[75px] p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400 font-sans placeholder:text-[14px]"
            // value={description}
            // onChange={(e) => setValue("description", e.target.value)}
          />
        </div>
        <div className="mt-5">
          <TextArea
            name="redemptionDescription"
            rules={{
              required: "Redemption description is required",
              maxLength: {
                value: 100,
                message: "Redemption description cannot exceed 100 characters",
              },
            }}
            label="Redemption description"
            placeholder="Must be charged to your room. Discount applied automatically at checkout."
            className="w-full h-[75px] p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400 font-sans placeholder:text-[14px]"
            // value={redemptionDescription}
            // onChange={(e) => setValue("redemptionDescription", e.target.value)}
          />
        </div>

        <div className="mt-5 grid grid-cols-3 gap-5">
          <Select
            name="costCenter"
            rules={{ required: "Cost Center is required" }}
            label="Cost Center"
            placeholder="Select Cost Center"
            // value={costCenter}
            options={costCenterOptions}
            onChange={(data) => {
              setValue("costSubCenter", null);
              setValue("costCenter", data);
            }}
            isDisabled={isEditOffer}
          />
          <Select
            name="costSubCenter"
            rules={{ required: "Cost Sub Center is required" }}
            label="Select Value"
            placeholder={
              costCenter ? `Select ${costCenter.label}` : "Select Value"
            }
            isDisabled={isEditOffer}
            // value={costSubCenter}
            options={subCentersOptions}
            // onChange={(data) => setValue("costSubCenter", data)}
          />
        </div>

        <div className="mt-5 grid grid-cols-3 gap-5">
          <Select
            name="type"
            rules={{ required: "Type is required" }}
            label="Offer Type"
            placeholder="Select Offer Type"
            options={offerTypeOptions}
            value={type}
            onChange={(data) => {
              setValue("type", data);
              setValue("value", null);
              setValue("cost", null);
            }}
            isDisabled={isEditOffer}
          />

          <TextInput
            name="value"
            rules={{ required: "Value is required" }}
            label="Enter Value"
            placeholder={`Enter ${
              CouponTypeKEY === "PERCENTAGE" || CouponTypeKEY === "FIXED_AMOUNT"
                ? `${type?.label ?? "Type"}`
                : "Value"
            }`}
            value={
              value
                ? CouponTypeKEY === "PERCENTAGE"
                  ? `${value}%`
                  : `$${value}`
                : ""
            }
            onChange={(e) => {
              const inputValue = e.target.value.replace(
                CouponTypeKEY === "PERCENTAGE" ? /%/g : /\$/g,
                ""
              );
              setValue("value", +inputValue);
              setValue("cost", null);
            }}
            disabled={isEditOffer}
          />

          {valueType === "VALUE_COST" && (
            <TextInput
              name="cost"
              rules={{
                required: "Cost is required",
                validate: (cost) => {
                  return Number(cost) > (Number(value) || 0)
                    ? "Cost should be less than value"
                    : true;
                },
              }}
              label="Cost"
              placeholder="Enter Cost"
              value={cost ? `$${cost}` : ""}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\$/g, "");
                setValue("cost", +inputValue);
                methods.trigger("cost");
              }}
              disabled={isEditOffer}
            />
          )}
        </div>

        {eligibilityCriteriaFields.map((field, index) => {
          const selectedEligibility =
            eligibilityCriteriaData?.result?.data.find(
              (x) =>
                x._id ===
                eligibilityCriterias?.[index]?.eligibilityCriteria?.value
            );

          const valueTypeEligibility = DynamicMultiSelectKeys.includes(
            selectedEligibility?.key!
          )
            ? "DYNAMIC_MULTI_SELECT"
            : selectedEligibility?.valueType;
          const eligibilityCriteriaOptions =
            selectedEligibility?.options?.map((x) => ({
              label: x.label,
              value: x.value,
            })) || [];

          return (
            <div key={field.id} className="grid grid-cols-3 gap-5 mt-5 ">
              <Select
                label={`Eligibility Criteria ${index + 1}`}
                name={`eligibilityCriterias.${index}.eligibilityCriteria`}
                rules={{
                  required: "Eligibility Criteria is required",
                  validate: (value) =>
                    value.value !== "" || "Eligibility Criteria is required",
                }}
                placeholder={`Select Eligibility Criteria ${index + 1}`}
                options={eligibilityOptions.filter(
                  (item) =>
                    ![
                      ...(index === 0 ? [] : disableEligibilityFieldsAddOn),
                      ...eligibilityCriterias.map(
                        (item) => item.eligibilityCriteria?.label
                      ),
                    ].includes(item.label)
                )}
                // value={eligibilityCriterias?.[0] || {}}
                onChange={(data) => {
                  if (disableEligibilityFieldsAddOn.includes(data.label)) {
                    setValue("eligibilityCriterias", []);
                  }
                  setValue(
                    `eligibilityCriterias.${index}.eligibilityCriteria`,
                    data
                  );
                  setValue(`eligibilityCriterias.${index}.data`, "");
                  setdynamicOptionsReset(true);
                }}
                isDisabled={isEditOffer}
              />
              <div className="col-start-2 -col-end-1 flex gap-4 [&>div]:flex-grow">
                {valueTypeEligibility === "TEXT" && (
                  <TextInput
                    name={`eligibilityCriterias.${index}.data`}
                    rules={{
                      required: "Shashi Cash & Active Reservation is required",
                    }}
                    label="Select Values"
                    placeholder={`Enter ${selectedEligibility?.name}`}
                    disabled={isEditOffer}
                  />
                )}

                {valueTypeEligibility === "DYNAMIC_MULTI_SELECT" && (
                  <DynamicMultiSelect
                    name={`eligibilityCriterias.${index}.data`}
                    rules={{
                      required: "Reservation Number is required",
                    }}
                    isMulti
                    label="Select Values"
                    placeholder={
                      DynamicMultiSelectKeys.includes(selectedEligibility?.key!)
                        ? `Enter ${selectedEligibility?.name}`
                        : `Select ${selectedEligibility?.name}`
                    }
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    checkboxOptions={true}
                    wrapperComponentStyle="col-start-2 -col-end-1 min-w-[50%] w-fit"
                    dynamicOptionsReset={dynamicOptionsReset}
                    handleDynamicOptionsReset={handleDynamicOptionsReset}
                    isDisabled={isEditOffer}
                  />
                )}

                {valueTypeEligibility === "SINGLE_SELECT" && (
                  <Select
                    name={`eligibilityCriterias.${index}.data`}
                    rules={{ required: "Value is required" }}
                    label="Select Values"
                    placeholder={`Select ${selectedEligibility?.name}`}
                    options={eligibilityCriteriaOptions}
                    isDisabled={isEditOffer}
                  />
                )}

                {valueTypeEligibility === "MULTI_SELECT" && (
                  <Select
                    name={`eligibilityCriterias.${index}.data`}
                    rules={{
                      required: "Shashi Cash & Active Reservation is required",
                    }}
                    isMulti
                    label="Select Values"
                    placeholder={`Select ${selectedEligibility?.name}`}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    checkboxOptions={true}
                    wrapperComponentStyle="col-start-2 -col-end-1 min-w-[50%] w-fit"
                    options={eligibilityCriteriaOptions}
                    isDisabled={isEditOffer}
                  />
                )}
                {!isEditOffer && (
                  <>
                    {index === 0 ? (
                      !!valueTypeEligibility &&
                      !disableEligibilityFieldsAddOn.includes(
                        selectedEligibility?.name!
                      ) ? (
                        <AddEligibilitySvg
                          className="mt-6"
                          onClick={eligibilityCriteriaAppendField}
                        />
                      ) : null
                    ) : (
                      <RemoveEligibilitySvg
                        className="mt-6"
                        onClick={() => eligibilityCriteriarRemoveField(index)}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
        <div className="grid grid-cols-3 gap-5 mt-5">
          <Select
            name="businessReason"
            rules={{ required: "Business Reason is required" }}
            label="Business Reason"
            placeholder="Select Business Reason"
            options={businessReasonOptions}
            isDisabled={isEditOffer}
          />
        </div>

        <div className="mt-9">
          <h3 className="text-[20px] font-bold leading-[26.4px] text-[#1E2F65]">
            Offer Details
          </h3>

          <div className="grid grid-cols-3 gap-5 mt-6.5">
            <Select
              name="redemptionLocation"
              rules={{
                required: !isEditOffer
                  ? "Redemption Location is required"
                  : false,
              }}
              label="Redemption Location"
              placeholder="Select Redemption Location"
              options={redemptionLocationOptions}
              isDisabled={isEditOffer}
            />
          </div>

          <div className="mt-7">
            <div className="flex items-center gap-2.5 mt-5">
              <FileInput
                id="tv-setup-image"
                name="tvSetupImage"
                rules={{ required: "Image is required" }}
                label="Offer Image"
                type="file"
                accept="image/*"
                className="block w-[240px] text-[10px] leading-[18px] text-[#666672] border border-[#D9D9D9] rounded cursor-pointer focus:outline-none file:bg-[#F41095] file:box-border file:p-2 file:text-[10px] file:leading-[18px] file:outline-none file:border-none file:border-r file:border-[#D9D9D9] file:text-white"
                onChange={handleFileChange}
                onClear={handleImageClear}
              />
            </div>

            {tvSetupImage ? (
              <div className="relative my-4">
                <img
                  src={
                    typeof tvSetupImage === "string"
                      ? tvSetupImage
                      : URL.createObjectURL(tvSetupImage)
                  }
                  alt="Cropped"
                  height={"auto"}
                  width={"229px"}
                  className="mx-auto"
                />
              </div>
            ) : null}

            {imageFile && (
              <ImageCropModal
                isOpen={isCropModalOpen}
                image={imageFile}
                onClose={() => setIsCropModalOpen(false)}
                onCropComplete={handleCropComplete}
              />
            )}
          </div>

          <DiscardSaveButtons
            onDiscard={() => {
              setValue("tvSetupImage", null);
              const fileInput = document.getElementById(id || "tv-setup-img");
              if (fileInput) {
                (fileInput as HTMLInputElement).value = "";
              }
              if (isEditOffer) {
                setValue("tvSetupImage", null);
              }
              handleImageClear();
              setIsCropModalOpen(false);
              clearValues();
            }}
            onSave={handleSubmit(onSubmit)}
            loading={loading || updateLoading}
            className="mt-10"
          />
        </div>
      </div>
    </FormProvider>
  );
};

export default CreateOfferTemplate;
