import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import Button from "../../../../components/common/button";
import Switch from "../../../../components/common/Switch";
import AddSleepingEssentialsModal from "../../../../components/tv-app/sleep-concierge/add-sleeping-essentials-modal";
import {
  ISleepCategoryComponentProps,
  ISleepingEssentialsSubData,
  ITableColumn,
} from "../../../../utils/types";
import DeleteAndroidHome from "../../../../components/tv-app/delete-android-home";
import EditTableRowName from "../../../../components/tv-app/edit-name";
import DiscardSaveButtons from "../../../../components/tv-app/discard-save-buttons";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

type IFormData = {
  showSE: boolean;
  data: ISleepingEssentialsSubData[];
};

const SleepingEssentials = ({
  subData,
  show,
  onSave,
  loading,
  categoryName,
  type,
}: ISleepCategoryComponentProps<ISleepingEssentialsSubData[]>) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const getInitialFormData = () => {
    return {
      showSE: !!show,
      data: (subData ?? [])
    }
  };

  const [InitialFormData, setInitialFormData] = useState<IFormData>(
    getInitialFormData()
  );

  const methods = useForm<IFormData>({ defaultValues: InitialFormData });

  const {
    fields: sleepCatagoryFields,
    append: appendSleepCatagoryField,
    remove: removeSleepCatagoryField,
  } = useFieldArray({ name: "data", control: methods.control , keyName : 'customId' });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    if (sleepCatagoryFields.length < subData.length) {
      const addedElement = subData[subData.length - 1];
      appendSleepCatagoryField({ ...addedElement });
      setInitialFormData((pre) => ({
        ...pre,
        data: [...pre.data, { ...addedElement }],
      }));
    }
  }, [subData]);

  // const formData = watch()

  const getTableDataIndex = (id : string) => {
    return sleepCatagoryFields.findIndex((item) => item.id === id);
  }

  const tableColumns: ITableColumn<ISleepingEssentialsSubData>[] = [
    {
      name: "Name",
      cell: (Row) => <EditTableRowName name={`data.${getTableDataIndex(Row.id)}.name`} />,
      sortable: true,
      width: "46%",
    },
    {
      name: "Qty",
      // selector: (Row) => Row.qty,
      cell: (Row) => <span className="text-[#666672]">{Row.qty}</span>,
      sortable: true,
      center: true,
      width: "18%",
    },
    {
      name: "View",
      cell: (Row) => <Switch name={`data.${getTableDataIndex(Row.id)}.selected`} />,
      sortable: true,
      center: true,
      width: "18%",
    },
    {
      name: "Status",
      cell: (Row) => (
        <DeleteAndroidHome
          categoryName={categoryName}
          type={type}
          deleteId={Row.id}
          removeField={() => {
            setInitialFormData((pre) => ({
              ...pre,
              data: pre.data.filter((item) => item.id !== Row.id),
            }));
            removeSleepCatagoryField(getTableDataIndex(Row.id));
          }}
        />
      ),
      sortable: true,
      center: true,
      width: "18%",
    },
  ];

  return (
    <FormProvider {...methods}>
      <div className="w-full mt-4">
        <div className="flex items-center justify-between w-full">
          {/* <Switch name="showSE" label="Sleeping Essentials (Hide/Show)" /> */}
          <h4 className="text-base leading-[39px] font-normal text-[#1E2F65] whitespace-nowrap">
            Sleeping Essentials
          </h4>
          <Button variant="primary" className="w-21" onClick={openModal}>
            Add New
          </Button>
        </div>
        {/* <div className="h-full max-h-[calc(100vh-450px)] overflow-auto mt-[17px]"> */}
        <DataTable
          className="border-t mt-6"
          data={sleepCatagoryFields}
          columns={tableColumns}
          striped={true}
          keyField="customId"
          persistTableHead
          pagination={sleepCatagoryFields.length > 10}
          customStyles={{
            headRow: { style: { paddingLeft: "36px" } },
            rows: { style: { paddingLeft: "36px" } },
          }}
        />
        {/* </div> */}

        <DiscardSaveButtons
          onDiscard={() => {
            reset(getInitialFormData());
          }}
          onSave={handleSubmit((submitData: IFormData) => {
            onSave({ show: submitData.showSE, subData: submitData.data });
          })}
          loading={loading}
          className="mt-10"
        />

        <AddSleepingEssentialsModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          categoryName={categoryName}
          type={type}
        />
      </div>
    </FormProvider>
  );
};

export default SleepingEssentials;
