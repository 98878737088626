import { useQuery } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseGetOfferTemplatesQueryResponse } from "../utils/types";

const useGetOfferTemplatesQuery = (): IUseGetOfferTemplatesQueryResponse =>
  useQuery({
    queryKey: ["offer-templates"],
    queryFn: async () => {
      const response = await AXIOS_RESERVATION.get("v1/coupons/templates");
      return response.data;
    },
  });

export default useGetOfferTemplatesQuery;
