import { SVGAttributes } from "react";

export const SidebarHomeSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.07861 16.1355H14.8936"
        // stroke="#F41095"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.3999 13.713C2.3999 8.082 3.0139 8.475 6.3189 5.41C7.7649 4.246 10.0149 2 11.9579 2C13.8999 2 16.1949 4.235 17.6539 5.41C20.9589 8.475 21.5719 8.082 21.5719 13.713C21.5719 22 19.6129 22 11.9859 22C4.3589 22 2.3999 22 2.3999 13.713Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SidebarMobileAppSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 12C2.75 5.063 5.063 2.75 12 2.75C18.937 2.75 21.25 5.063 21.25 12C21.25 18.937 18.937 21.25 12 21.25C5.063 21.25 2.75 18.937 2.75 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2041 13.8999H15.2131"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2041 9.8999H12.2131"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.19531 13.8999H9.20431"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SidebarMasterUsersSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3053 15.45H8.90527"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2608 11.4387H8.90479"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1599 8.3L14.4899 2.9C13.7599 2.8 12.9399 2.75 12.0399 2.75C5.7499 2.75 3.6499 5.07 3.6499 12C3.6499 18.94 5.7499 21.25 12.0399 21.25C18.3399 21.25 20.4399 18.94 20.4399 12C20.4399 10.58 20.3499 9.35 20.1599 8.3Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9341 2.83276V5.49376C13.9341 7.35176 15.4401 8.85676 17.2981 8.85676H20.2491"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SidebarGlobalSettingsSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.30566 14.5742H16.8987"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 7.79836C2.5 5.35646 3.75 3.25932 6.122 2.77265C8.493 2.28503 10.295 2.4536 11.792 3.26122C13.29 4.06884 12.861 5.26122 14.4 6.13646C15.94 7.01265 18.417 5.69646 20.035 7.44217C21.729 9.26979 21.72 12.0755 21.72 13.8641C21.72 20.6603 17.913 21.1993 12.11 21.1993C6.307 21.1993 2.5 20.7288 2.5 13.8641V7.79836Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AmbientSceneSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="47.4291"
      height="47.4291"
      rx="23.7145"
      fill="url(#paint0_linear_10578_7633)"
    />
    <g clipPath="url(#clip0_10578_7633)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5136 29.7825C14.9949 28.1126 14.5799 26 14.7646 24.1127C14.9682 22.0308 15.9178 20.0338 17.1539 19.0032C18.806 17.6268 20.5411 17.0616 22.047 16.7849C22.7804 16.6597 23.5188 16.5664 24.2603 16.5053C24.6595 16.4678 25.0637 16.4302 25.452 16.3334C26.2701 16.1288 27.0774 15.7968 27.7424 15.2692C28.0468 15.0271 28.355 14.7821 28.7651 14.8275C28.9125 14.8439 29.0543 14.8933 29.1801 14.972C29.3058 15.0506 29.4122 15.1566 29.4914 15.282C32.6533 20.2888 32.176 25.6038 29.5348 28.9515C28.2157 30.6224 26.363 31.7883 24.202 32.1332C22.3167 32.4326 20.2565 32.0976 18.1805 31.0107C17.9981 31.5776 17.8626 32.1586 17.7754 32.7478C17.7383 33.0072 17.5997 33.2413 17.39 33.3985C17.1803 33.5558 16.9168 33.6233 16.6574 33.5862C16.3979 33.5491 16.1638 33.4105 16.0066 33.2008C15.8494 32.9911 15.7819 32.7276 15.819 32.4681C15.9415 31.6134 16.1747 30.7034 16.5136 29.7825ZM22.4027 18.7285C23.0983 18.6011 23.7515 18.5398 24.3858 18.4776C24.9036 18.4282 25.4253 18.3768 25.9312 18.2503C26.7832 18.0416 27.5992 17.7065 28.3521 17.2563C30.5042 21.3243 29.9034 25.2935 27.9835 27.7282C26.948 29.0404 25.5241 29.9218 23.8928 30.1807C22.4274 30.4139 20.7308 30.157 18.9226 29.1669C20.0214 26.9269 21.8177 24.7797 24.1566 23.6108C24.3911 23.4936 24.5695 23.2881 24.6526 23.0394C24.7356 22.7908 24.7164 22.5193 24.5993 22.2847C24.4821 22.0502 24.2766 21.8718 24.0279 21.7888C23.7792 21.7058 23.5078 21.7249 23.2732 21.8421C20.6962 23.1306 18.722 25.3587 17.4385 27.7351C16.8051 26.6976 16.6154 25.4911 16.732 24.3044C16.899 22.597 17.6805 21.1375 18.4187 20.521C19.7309 19.4271 21.1142 18.9657 22.4037 18.7285H22.4027Z"
        fill="#FCEA17"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10578_7633"
        x1="0"
        y1="23.7145"
        x2="47.4291"
        y2="23.7145"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.265" stopColor="#FFFCE1" />
        <stop offset="1" stopColor="#FFF8AC" />
      </linearGradient>
      <clipPath id="clip0_10578_7633">
        <rect
          width="23.7145"
          height="23.7145"
          fill="white"
          transform="translate(11.8579 11.8572)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const RelexSceneSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="47.4291"
      height="47.4291"
      rx="23.7145"
      fill="url(#paint0_linear_10578_7626)"
    />
    <path
      d="M24.7035 29.0787C26.3406 29.0787 27.6678 27.5619 27.6678 25.6909M29.8359 19.2322C28.4315 21.4601 25.9005 22.4118 24.0988 21.3562C22.2499 20.2731 21.8692 17.4865 23.2485 15.1322C23.4667 14.7597 23.7159 14.4204 23.9881 14.1173C23.5052 14.0604 23.0087 14.1552 22.5714 14.4018C18.7478 16.5577 15.8105 20.865 15.8105 25.1263C15.8105 29.8038 19.3497 33.5957 23.7154 33.5957C28.0811 33.5957 31.6202 29.8038 31.6202 25.1263C31.6202 23.0924 30.9511 21.0481 29.8359 19.2322Z"
      stroke="#FEDB1B"
      strokeWidth="1.48216"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10578_7626"
        x1="0"
        y1="23.7145"
        x2="47.4291"
        y2="23.7145"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.205" stopColor="#FCF9D9" />
        <stop offset="1" stopColor="#FFF699" />
      </linearGradient>
    </defs>
  </svg>
);

export const EnergiseSceneSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="47.4291"
      height="47.4291"
      rx="23.7145"
      fill="url(#paint0_linear_10578_7619)"
    />
    <path
      d="M20.5036 22.2324C20.5036 22.7739 20.1616 22.9735 20.0095 22.9735C19.8575 22.9735 19.5155 22.7739 19.5155 22.2324C19.5155 21.6909 19.8575 21.4913 20.0095 21.4913C20.1616 21.4913 20.5036 21.6909 20.5036 22.2324ZM27.9144 22.2324C27.9144 22.7739 27.5724 22.9735 27.4203 22.9735C27.2683 22.9735 26.9263 22.7739 26.9263 22.2324C26.9263 21.6909 27.2683 21.4913 27.4203 21.4913C27.5724 21.4913 27.9144 21.6909 27.9144 22.2324Z"
      stroke="#BBAD00"
      strokeOpacity="0.2"
      strokeWidth="1.48216"
    />
    <path
      d="M19.9615 25.9435C20.4819 27.5185 21.9658 28.655 23.7152 28.655C25.4646 28.655 26.9485 27.5185 27.4689 25.9435M32.6082 23.7145C32.6082 18.803 28.6267 14.8215 23.7152 14.8215C18.8038 14.8215 14.8223 18.803 14.8223 23.7145C14.8223 28.6259 18.8038 32.6074 23.7152 32.6074C28.6267 32.6074 32.6082 28.6259 32.6082 23.7145Z"
      stroke="#BBAD00"
      strokeOpacity="0.2"
      strokeWidth="1.48216"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10578_7619"
        x1="0"
        y1="23.7145"
        x2="47.4291"
        y2="23.7145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCF9D9" />
        <stop offset="1" stopColor="#FFF372" />
      </linearGradient>
    </defs>
  </svg>
);

export const CoolBrightSceneSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="rounded-full shadow-[0px_4.77px_4.77px_0px_#0000001A]"
  >
    <rect width="47.4291" height="47.4291" rx="23.7145" fill="white" />
    <path
      d="M23.715 13.8337V16.7981M30.702 16.7278L28.6059 18.8239M33.5961 23.7148H30.6318M30.702 30.7018L28.6059 28.6057M23.715 30.6315V33.5958M18.8242 28.6057L16.7281 30.7018M16.7983 23.7148H13.834M18.8242 18.8239L16.7281 16.7278M23.715 27.6672C21.5322 27.6672 19.7626 25.8977 19.7626 23.7148C19.7626 21.5319 21.5322 19.7624 23.715 19.7624C25.8979 19.7624 27.6675 21.5319 27.6675 23.7148C27.6675 25.8977 25.8979 27.6672 23.715 27.6672Z"
      stroke="#404040"
      stroke-opacity="0.1"
      stroke-width="1.48216"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const BlueSceneSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="47.4291" height="47.4291" rx="23.7145" fill="#2540FF" />
    <path
      d="M32.6082 23.7147C32.6082 28.6262 28.6267 32.6077 23.7152 32.6077C18.8038 32.6077 14.8223 28.6262 14.8223 23.7147C14.8223 18.8033 18.8038 14.8218 23.7152 14.8218C28.6267 14.8218 32.6082 18.8033 32.6082 23.7147Z"
      stroke="#5268FF"
      strokeWidth="1.48216"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6795 23.7147C26.6795 25.3519 25.3524 26.679 23.7152 26.679C22.0781 26.679 20.7509 25.3519 20.7509 23.7147C20.7509 22.0776 22.0781 20.7504 23.7152 20.7504C25.3524 20.7504 26.6795 22.0776 26.6795 23.7147Z"
      stroke="#5268FF"
      strokeWidth="1.48216"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PinkSceneSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="47.4291" height="47.4291" rx="23.7145" fill="#F41095" />
    <path
      d="M27.3759 15.8098C25.9478 15.8107 24.6533 16.3532 23.7113 17.229C22.767 16.3563 21.4715 15.8169 20.0418 15.8185C17.156 15.8214 14.8198 18.0258 14.8223 20.74C14.8285 25.4536 19.9121 29.2566 22.3949 30.8331C23.215 31.3538 24.2405 31.3527 25.0594 30.8302C27.5392 29.2482 32.6154 25.4333 32.6082 20.7183C32.6045 18.0039 30.2625 15.8067 27.3759 15.8098Z"
      stroke="#FF7AC8"
      strokeWidth="1.48216"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SidebarToggleSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0005 6.6738C21.0005 8.7024 19.3553 10.3476 17.3267 10.3476C15.2981 10.3476 13.6538 8.7024 13.6538 6.6738C13.6538 4.6452 15.2981 3 17.3267 3C19.3553 3 21.0005 4.6452 21.0005 6.6738Z"
      stroke="#F41095"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3467 6.6738C10.3467 8.7024 8.7024 10.3476 6.6729 10.3476C4.6452 10.3476 3 8.7024 3 6.6738C3 4.6452 4.6452 3 6.6729 3C8.7024 3 10.3467 4.6452 10.3467 6.6738Z"
      stroke="#F41095"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0005 17.2619C21.0005 19.2905 19.3553 20.9348 17.3267 20.9348C15.2981 20.9348 13.6538 19.2905 13.6538 17.2619C13.6538 15.2333 15.2981 13.5881 17.3267 13.5881C19.3553 13.5881 21.0005 15.2333 21.0005 17.2619Z"
      stroke="#F41095"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3467 17.2619C10.3467 19.2905 8.7024 20.9348 6.6729 20.9348C4.6452 20.9348 3 19.2905 3 17.2619C3 15.2333 4.6452 13.5881 6.6729 13.5881C8.7024 13.5881 10.3467 15.2333 10.3467 17.2619Z"
      stroke="#F41095"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DragSvg = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.16683 4.125H1.8335V5.04167H9.16683V4.125ZM1.8335 6.875H9.16683V5.95833H1.8335V6.875Z"
      fill="#1E2F65"
    />
  </svg>
);

export const TrashSvg = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.6665 14C4.29984 14 3.98595 13.8694 3.72484 13.6083C3.46373 13.3472 3.33317 13.0333 3.33317 12.6667V4H2.6665V2.66667H5.99984V2H9.99984V2.66667H13.3332V4H12.6665V12.6667C12.6665 13.0333 12.5359 13.3472 12.2748 13.6083C12.0137 13.8694 11.6998 14 11.3332 14H4.6665ZM11.3332 4H4.6665V12.6667H11.3332V4ZM5.99984 11.3333H7.33317V5.33333H5.99984V11.3333ZM8.6665 11.3333H9.99984V5.33333H8.6665V11.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const CheckboxTickSvg = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M20.285 6.707l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414l4.293 4.293 10.293-10.293a1 1 0 1 1 1.414 1.414z" />
    </svg>
  );
};

export const CloseSvg = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.3034 9.69684L9.69678 20.3034M20.3034 20.3034L9.69678 9.69678"
        stroke="currentColor"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AddEligibilitySvg = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="37" height="37" rx="4.5" stroke="#F41095" />
      <path
        d="M18.0788 25V19.878H13V18.122H18.0788V13H19.9212V18.122H25V19.878H19.9212V25H18.0788Z"
        fill="#F41095"
      />
    </svg>
  );
};

export const RemoveEligibilitySvg = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="37" height="37" rx="4.5" stroke="#F41095" />
      <path
        d="M18.0788 19.7563H13V18.0002H18.0788L19.9212 18L25 18.0002V19.7563H19.9212H18.0788Z"
        fill="#F41095"
      />
    </svg>
  );
};

export const EditSvg = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="25"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.50391 14H14.5039M9.69351 3.54114C9.69351 3.54114 9.69351 4.63089 10.7833 5.72064C11.873 6.81039 12.9628 6.81039 12.9628 6.81039M5.38366 11.992L7.67213 11.6651C8.00224 11.618 8.30814 11.465 8.54393 11.2292L14.0525 5.72064C14.6544 5.11879 14.6544 4.14299 14.0525 3.54114L12.9628 2.45139C12.3609 1.84954 11.3851 1.84954 10.7833 2.45139L5.27468 7.95997C5.03889 8.19576 4.88594 8.50167 4.83878 8.83177L4.51186 11.1202C4.43921 11.6288 4.87511 12.0647 5.38366 11.992Z"
        stroke="#6362E7"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const CustomOfferSvg = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.1782 16V8.78049H0V7.21951H7.1782V0H8.8218V7.21951H16V8.78049H8.8218V16H7.1782Z"
        fill="black"
      />
    </svg>
  );
};

export const EclipseSvg = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20.5" cy="20.5" r="20.5" fill="#D9D9D9" />
    </svg>
  );
};

export const LeftArrowSvg = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.67383 1.50866L7.49883 0.333659L0.832161 7.00033L7.49883 13.667L8.67383 12.492L3.19049 7.00033"
        fill="#1E2F65"
      />
    </svg>
  );
};