// @ts-ignore
import CKEditors from "react-ckeditor-component";
// import Switch from "../../../components/common/Switch";
import { useRef } from "react";
import { CloseSvg } from "../../../icons";
import DiscardSaveButtons from "../../../components/tv-app/discard-save-buttons";
import { ICategoryComponentProps } from "../../../utils/types";
import { FormProvider, useForm } from "react-hook-form";

interface IFormData {
  showCasting: boolean;
  image: File | string;
  description: string;
}

const Casting = ({ data, onSave, loading }: ICategoryComponentProps) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const getInitialFormData = () => ({
    showCasting: !!data?.castSettings?.show,
    image: data?.castSettings?.image || data?.image || "",
    description: data?.castSettings?.description || data?.description || "",
  });

  const methods = useForm<IFormData>({ defaultValues: getInitialFormData() });

  const { setValue, watch, handleSubmit, reset } = methods;

  const { image, description } = watch();

  const onChange = (evt: any) => {
    const newDescription = evt.editor.getData();
    // setDescription(newDescription);
    setValue("description", newDescription);
  };

  return (
    <FormProvider {...methods}>
      <div>
        <h3 className="text-[22px] font-normal text-[#1E2F65]">Casting</h3>
        {/* <div className="">
           <Switch
            name="showCasting"
            label="Casting (Hide/Show)"
            // checked={showCasting}
            // onCheck={(checked) => setShowCasting(checked)}
          /> 
        </div> */}
        <CKEditors
          activeclassName="p10"
          content={description}
          events={{
            change: onChange,
          }}
        />
        <div className="mt-7">
          <h3 className="text-base font-normal text-[#1E2F65]">
            Default Casting Image
          </h3>
          <div className="flex items-center gap-2.5 mt-5">
            <input
              ref={inputFileRef}
              type="file"
              accept="image/*"
              className="block w-full text-[10px] leading-[18px] text-[#666672] border border-[#D9D9D9] rounded cursor-pointer focus:outline-none file:bg-[#F41095] file:box-border file:p-2 file:text-[10px] file:leading-[18px] file:outline-none file:border-none file:border-r file:border-[#D9D9D9] file:text-white"
              onChange={(e) => {
                if (e.target.files!?.[0])
                  setValue("image", e.target.files!?.[0]);
              }}
            />
            <CloseSvg
              height={25}
              width={25}
              className="cursor-pointer"
              onClick={() => {
                setValue("image", "");
                inputFileRef.current!.value = "";
              }}
            />
          </div>

          <div className="mt-10 flex items-center justify-center relative">
            <img
              src={
                typeof image === "string" ? image : URL.createObjectURL(image)
              }
              height={"auto"}
              width={"160px"}
            />
          </div>
        </div>

        <DiscardSaveButtons
          onDiscard={() => {
            reset(getInitialFormData());
            inputFileRef.current!.value = "";
          }}
          onSave={handleSubmit((submitData: IFormData) => {
            const formData = new FormData();
            if (typeof image !== "string")
              formData.append("image", submitData.image);
            const updatedData = {
              ...data,
              castSettings: {
                ...(data?.castSettings || {}),
                show: submitData.showCasting,
                description: submitData.description,
              },
            };
            formData.append("categoryName", updatedData.categoryName);
            formData.append("data", JSON.stringify({ ...updatedData }));
            onSave(formData);
          })}
          loading={loading}
          className="mt-10"
        />
      </div>
    </FormProvider>
  );
};

export default Casting;
