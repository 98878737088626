import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import Button from "../../../../components/common/button";
import Switch from "../../../../components/common/Switch";
import AddTVOffTimerModal from "../../../../components/tv-app/sleep-concierge/add-tv-off-timer-modal";
import DeleteAndroidHome from "../../../../components/tv-app/delete-android-home";
import EditTableRowName from "../../../../components/tv-app/edit-name";
import DiscardSaveButtons from "../../../../components/tv-app/discard-save-buttons";
import {
  ISleepCategoryComponentProps,
  ITableColumn,
  ITVOffTimerSubData,
} from "../../../../utils/types";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

type IFormData = {
  showTOT: boolean;
  data: ITVOffTimerSubData[];
};

const TVOffTimer = ({
  subData,
  show,
  onSave,
  loading,
  categoryName,
  type,
}: ISleepCategoryComponentProps<ITVOffTimerSubData[]>) => {
  const getInitialFormData = () => {
    return {
      showTOT: !!show,
      data: subData ?? [],
    };
  };

  const [InitialFormData, setInitialFormData] = useState<IFormData>(
    getInitialFormData()
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const methods = useForm<IFormData>({ defaultValues: InitialFormData });
  const { reset, handleSubmit } = methods;

  const {
    fields: sleepCatagoryTOTFields,
    append: appendSleepCatagoryTOTField,
    remove: removeSleepCatagoryTOTField,
  } = useFieldArray({
    name: "data",
    control: methods.control,
    keyName: "customId",
  });

  useEffect(() => {
    // if (subData) setData(JSON.parse(JSON.stringify(subData)));
    if (sleepCatagoryTOTFields.length < subData.length) {
      const addedElement = subData[subData.length - 1];
      appendSleepCatagoryTOTField({ ...addedElement });
      setInitialFormData((pre) => ({
        ...pre,
        data: [...pre.data, { ...addedElement }],
      }));
    }
  }, [subData]);

  const getTableDataIndex = (id : string) => {
    return sleepCatagoryTOTFields.findIndex((item) => item.id === id);
  }

  const tableColumns: ITableColumn<ITVOffTimerSubData>[] = [
    {
      name: "Name",
      cell: (Row) => <EditTableRowName name={`data.${getTableDataIndex(Row.id)}.name`} />,
      sortable: true,
      width: "60%",
    },
    {
      name: "View",
      cell: (Row) => <Switch name={`data.${getTableDataIndex(Row.id)}.selected`} />,
      sortable: true,
      center: true,
      width: "20%",
    },
    {
      name: "Status",
      cell: (Row) => (
        <DeleteAndroidHome
          categoryName={categoryName}
          type={type}
          deleteId={Row.id}
          removeField={() => {
            setInitialFormData((pre) => ({
              ...pre,
              data: pre.data.filter((item) => item.id !== Row.id),
            }));
            removeSleepCatagoryTOTField(getTableDataIndex(Row.id));
          }}
        />
      )
    },
  ];

  return (
    <FormProvider {...methods}>
    <div className="w-full mt-4">
      <div className="flex items-center justify-between w-full">
        {/* <Switch
          name="showTOT"
          label="TV Off Timer (Hide/Show)"
          // checked={showTOT}
          // onCheck={(checked) => setShowTOT(checked)}
        /> */}
         <h4 className="text-base leading-[39px] font-normal text-[#1E2F65] whitespace-nowrap">
            TV Off Timer
          </h4>
        <Button variant="primary" className="w-21" onClick={openModal}>
          Add New
        </Button>
      </div>
      {/* <div className="h-full max-h-[calc(100vh-450px)] overflow-auto mt-[17px]"> */}
      <DataTable
        className="border-t mt-[17px]"
        data={sleepCatagoryTOTFields}
        // @ts-ignore
        columns={tableColumns}
        striped={true}
        persistTableHead
        pagination={sleepCatagoryTOTFields.length > 10}
        customStyles={{
          headRow: { style: { paddingLeft: "36px" } },
          rows: { style: { paddingLeft: "36px" } },
        }}
      />
      {/* </div> */}

      <DiscardSaveButtons
        onDiscard={() => {
          reset(getInitialFormData());
        }}
        onSave={handleSubmit((submitData: IFormData)  => {
          onSave({
            show: submitData.showTOT,
            subData: submitData.data,
          });
        })}
        loading={loading}
        className="mt-10"
      />

      <AddTVOffTimerModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        categoryName={categoryName}
        type={type}
      />
    </div>
    </FormProvider>
  );
};

export default TVOffTimer;
