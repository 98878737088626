import { useMutation } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseAddOffersHomeMutationResponse } from "../utils/types";

const useAddDraftOffer = (): IUseAddOffersHomeMutationResponse =>
  useMutation({
    mutationFn: async (data) => {
      const response = await AXIOS_RESERVATION.post("v1/coupons/draft", data);
      return response.data;
    },
  });

export default useAddDraftOffer;
