import { useState } from "react";
import Switch from "../../../../components/common/Switch";
import { TableColumn } from "react-data-table-component";
import DataTable from "react-data-table-component";
import useGetOfferQuery from "../../../../hooks/use-get-offers";
import { TrashSvg } from "../../../../icons";
import Modal from "../../../../components/common/Modal";
import Button from "../../../../components/common/button";
import EditOffer from "../../../../components/common/offer/edit-offer";
import useDeleteOffer from "../../../../hooks/use-delete-offer";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IOffer } from "../../../../utils/types";
import useDeleteOfferAttachment from "../../../../hooks/use-delete-attachment";
import { Link, useParams } from "react-router-dom";
import StatusBadge from "../../../../components/common/Status";
import useActivateOffer from "../../../../hooks/use-activate-offer";
import { errorToast, successToast } from "../../../../utils/helpers";
import { queryClient } from "../../../../App";
import useDeactivateOffer from "../../../../hooks/use-deactivate-offer";
import Spinner from "../../../../components/common/Spinner";
import classNames from "classnames";

const CurrentOffer = () => {
  const params = useParams();
  const methods = useForm<{ showOffer: boolean }>({
    defaultValues: { showOffer: false },
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDetachModalOpen, setIsDetachModalOpen] = useState<boolean>(false);
  const [activeOfferModal, setAtiveOfferModal] = useState<{
    open: boolean;
    data: any;
  }>({
    open: false,
    data: { active: false },
  });
  const [editOfferModalOpen, setEditOfferModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openDetachModal = () => {
    setIsDetachModalOpen(true);
  };
  const closeDetachModal = () => {
    setIsDetachModalOpen(false);
  };
  const closeActivationModal = () => {
    setAtiveOfferModal({ open: false, data: undefined });
  };

  const {
    data: offersData,
    isPending: getOffersLoading,
    isFetching: getOffersFetching,
  } = useGetOfferQuery({ page: currentPage, perPage: rowsPerPage });
  const { mutate: deleteOffer, isPending: deleteOfferLoading } =
    useDeleteOffer();
  const {
    mutate: deleteAttachmentOffer,
    isPending: deleteAttachmentOfferLoading,
  } = useDeleteOfferAttachment();
  const { mutate: activateOffer, isPending: activateOfferLoading } =
    useActivateOffer();
  const { mutate: deactivateOffer, isPending: deactivateOfferLoading } =
    useDeactivateOffer();

  const offerPaginatedData = offersData?.result?.data;

  const formatDate = (dateString: string) => {
    if (!dateString) {
      return "-";
    }

    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    if (isNaN(date.getTime())) {
      return "-";
    }

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isUSTimeZone = userTimeZone.startsWith("America/");

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      timeZone: isUSTimeZone ? "America/New_York" : "Asia/Kolkata",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  const handleDelete = (id: string) => {
    deleteOffer(
      { id },
      {
        onSuccess: (res) => {
          toast.success(res?.result?.message);
          closeModal();
        },
        onError: (error) => {
          closeModal();
          const message =
            error.response?.data.result.message ||
            "An unexpected error occurred";
          setErrorMessage(message);
          openDetachModal();
        },
      }
    );
  };

  const handleDetachOffer = (id: string) => {
    deleteAttachmentOffer(
      { id },
      {
        onSuccess: (res) => {
          toast.success(res?.result?.message);
          closeDetachModal();
        },
        onError: (error) => {
          closeDetachModal();

          toast.error(error.response?.data.result.message);
        },
      }
    );
  };

  const onActiveOffer = () => {
    activateOffer(
      { id: selectedId },
      {
        onSuccess: async (res) => {
          successToast(res.result.message);
          closeActivationModal();
          setSelectedId(null);
          await queryClient.refetchQueries({
            queryKey: ["offers"],
          });
        },
        onError: (err) => {
          errorToast(err?.response?.data?.result?.message, 10000);
          if (err?.response?.data?.result?.error === "MISSING_FIELDS") {
            closeActivationModal();
            setEditOfferModalOpen(true);
          }
        },
      }
    );
  };

  const onDeactiveOffer = () => {
    deactivateOffer(
      { id: selectedId },
      {
        onSuccess: async (res) => {
          successToast(res.result.message);
          closeActivationModal();
          setSelectedId(null);
          await queryClient.refetchQueries({
            queryKey: ["offers"],
          });
        },
        onError: (err) => {
          errorToast(err?.response?.data?.result?.message);
        },
      }
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage: number) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(1);
  };

  const tableColumns: Array<TableColumn<IOffer>> = [
    {
      name: "View",
      cell: (Row) => {
        const active = !Row.isDraft && !Row.expired;
        return (
          <div>
            <Switch
              name={`view-${Row._id}`}
              checked={active}
              onCheck={() => {
                setSelectedId(Row._id);
                setAtiveOfferModal({ open: true, data: { active } });
              }}
              disabled={getOffersFetching}
            />
          </div>
        );
      },
      sortable: true,
      width: "10%",
    },
    {
      name: "Name",
      selector: (Row) => Row.name,
      sortable: true,
      width: "25%",
    },
    {
      name: "Start Date",
      selector: (Row: IOffer) => formatDate(Row.startDate),
      sortable: true,
      width: "15%",
    },
    {
      name: "End Date",
      selector: (Row: IOffer) => formatDate(Row.endDate),
      sortable: true,
      width: "15%",
    },
    {
      name: "Status",
      cell: (Row) => {
        return (
          <StatusBadge
            status={Row.isDraft ? "DRAFT" : Row.expired ? "EXPIRED" : "ACTIVE"}
            active={!Row.isDraft && !Row.expired}
          />
        );
      },
      sortable: true,
      width: "20%",
      center: true,
    },
    {
      name: "Action",
      cell: (Row: IOffer) => (
        <div className="flex items-center gap-2">
          <div
            onClick={() => {
              setSelectedId(Row._id);
            }}
          >
            <EditOffer
              data={Row}
              isDraftEditOffer={Row.isDraft}
              onClose={async () => {
                await queryClient.refetchQueries({ queryKey: ["offers"] });
              }}
              disabled={getOffersFetching}
            />
          </div>
          <div
            onClick={
              getOffersFetching
                ? undefined
                : () => {
                    setSelectedId(Row._id);
                    openModal();
                  }
            }
            className={classNames(
              "h-6 w-6 rounded-[3.63px] bg-[#E5272733] flex items-center justify-center",
              getOffersFetching ? "opacity-50" : "cursor-pointer"
            )}
          >
            <TrashSvg color="#E52727" />
          </div>
        </div>
      ),
      sortable: true,
      width: "15%",
    },
  ];
  return (
    <div>
      <FormProvider {...methods}>
        <div className="flex justify-between items-center mt-6.25">
          <h3 className="text-[14px] font-normal text-[#1E2F65]">
            Current Offers
          </h3>
          <div className="flex items-center gap-3">
            <Link
              to={`/${params.hotelcode}/tv-app/offers/offers/templates/create`}
            >
              <Button variant="primary">Create Template</Button>
            </Link>
            <Link to={`/${params.hotelcode}/tv-app/offers/offers/templates`}>
              <Button variant="primary">Create Offer / View Templates</Button>
            </Link>
          </div>
        </div>
        {/* <div>
          <Switch label="Offers (Hide/Show)" name="showOffer" />
        </div> */}
        <DataTable
          className="border-t mt-6"
          columns={tableColumns}
          striped={true}
          persistTableHead
          pagination={offerPaginatedData!?.total > 10}
          paginationServer
          paginationTotalRows={offerPaginatedData!?.total}
          paginationDefaultPage={currentPage}
          onChangePage={handlePageChange}
          paginationPerPage={rowsPerPage}
          onChangeRowsPerPage={handleRowsPerPageChange}
          data={offerPaginatedData?.items!}
          customStyles={{
            headRow: { style: { paddingLeft: "36px" } },
            rows: { style: { paddingLeft: "36px" } },
          }}
          progressPending={getOffersLoading}
          progressComponent={
            <div className="mt-10">
              <Spinner />
            </div>
          }
        />
      </FormProvider>

      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title="Are you sure you want to delete this offer?"
        className="w-144.75"
      >
        <div className="flex items-center justify-end gap-[18px] mt-9">
          <Button className="w-25.5" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDelete(selectedId!);
            }}
            loading={deleteOfferLoading}
            disabled={deleteOfferLoading}
            className="w-25.5"
            variant="primary"
          >
            Delete
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={isDetachModalOpen}
        closeModal={closeDetachModal}
        title={errorMessage || "Detach this offer?"}
        className="w-144.75"
      >
        <div className="flex items-center justify-end gap-[18px] mt-9">
          <Button className="w-25.5" onClick={closeDetachModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              openDetachModal();
              handleDetachOffer(selectedId!);
            }}
            loading={deleteAttachmentOfferLoading}
            disabled={deleteAttachmentOfferLoading}
            className="w-25.5"
            variant="primary"
          >
            Detach
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={activeOfferModal?.open}
        closeModal={closeActivationModal}
        title={`Are you sure to ${
          activeOfferModal.data?.active ? "deactivate" : "activate"
        } this offer?`}
        className="w-144.75"
      >
        <div className="flex items-center justify-end gap-[18px] mt-9">
          <Button className="w-25.5" onClick={closeActivationModal}>
            Cancel
          </Button>
          <Button
            onClick={
              activeOfferModal.data?.active ? onDeactiveOffer : onActiveOffer
            }
            loading={activateOfferLoading || deactivateOfferLoading}
            disabled={activateOfferLoading || deactivateOfferLoading}
            className="min-w-28"
            variant="primary"
          >
            {activateOfferLoading || deactivateOfferLoading
              ? activeOfferModal.data?.active
                ? "Deactivating"
                : "Activating"
              : activeOfferModal.data?.active
              ? "Deactivate"
              : "Activate"}
          </Button>
        </div>
      </Modal>
      {selectedId && editOfferModalOpen && (
        <EditOffer
          data={offerPaginatedData?.items.find((x) => x._id === selectedId)!}
          defaultOpen
          showIcon={false}
          onClose={() => {
            setSelectedId(null);
            setEditOfferModalOpen(false);
          }}
          isDraftEditOffer
        />
      )}
    </div>
  );
};

export default CurrentOffer;
