import { useMutation } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseAddOffersHomeMutationResponse } from "../utils/types";

const useAddOfferTemplate = (): IUseAddOffersHomeMutationResponse =>
  useMutation({
    mutationFn: async (data) => {
      const response = await AXIOS_RESERVATION.post(
        "v1/coupons/templates",
        data
      );
      return response.data;
    },
  });

export default useAddOfferTemplate;
