import classNames from "classnames";

const StatusBadge = ({
  active,
  status,
}: {
  active: boolean;
  status: "ACTIVE" | "EXPIRED" | "DRAFT";
}) => {
  return (
    <div
      className={classNames(
        "px-[9px] py-[6.5px] flex justify-center items-center",
        status === "ACTIVE"
          ? "bg-[#00B5191A]"
          : status === "DRAFT"
          ? "bg-secondary/10"
          : "bg-orange-500/10"
      )}
    >
      <h3
        className={classNames(
          "text-[9px] leading-[15.75px] font-normal",
          status === "ACTIVE"
            ? "text-[#00B049]"
            : status === "DRAFT"
            ? "text-secondary"
            : "text-orange-500"
        )}
      >
        {status === "ACTIVE"
          ? "Active"
          : status === "DRAFT"
          ? "Draft"
          : "Expired"}
      </h3>
    </div>
  );
};

export default StatusBadge;
