import { useQuery } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseGetOffersQueryResponse } from "../utils/types";

const useGetOffersQuery = (data: {
  page: number;
  perPage: number;
}): IUseGetOffersQueryResponse =>
  useQuery({
    queryKey: ["offers", data.page, data.perPage],
    queryFn: async () => {
      const response = await AXIOS_RESERVATION.get("v1/coupons", {
        params: data,
      });
      return response.data;
    },
  });

export default useGetOffersQuery;
