// import Switch from "../../../components/common/Switch";
import Select, { ISelectOptionType } from "../../../components/common/Select";
import {
  FAN_SPEED_OPTIONS,
  TEMPERATURE_OPTIONS,
  TEMPERATURE_UNIT_OPTIONS,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import DiscardSaveButtons from "../../../components/tv-app/discard-save-buttons";
import { ICategoryComponentProps } from "../../../utils/types";
import { FormProvider, useForm } from "react-hook-form";

type FanSpeed = {
  show: boolean;
  defaultSpeed?: ISelectOptionType;
};

type Temperature = {
  show: boolean;
  defaultTemperature?: ISelectOptionType;
  minTemperature?: ISelectOptionType;
  maxTemperature?: ISelectOptionType;
};

type TemperatureType = {
  show: boolean;
  defaultTemperatureType?: ISelectOptionType;
};

interface IFormData {
  fanSpeed: FanSpeed;
  temperature: Temperature;
  temperatureType: TemperatureType;
}

const Temperature = ({ data, onSave, loading }: ICategoryComponentProps) => {
  // const [formData, setFormData] = useState<Partial<IFormData>>({});

  const getInitialFormData = ()=> ({
    fanSpeed: {
      show: !!data?.temperatureSettings?.fanSpeed?.show,
      defaultSpeed: data?.temperatureSettings?.fanSpeed?.defaultSpeed
        ? FAN_SPEED_OPTIONS.find(
            (x) => x.value == data?.temperatureSettings?.fanSpeed?.defaultSpeed
          )
        : undefined,
    },
    temperature: {
      show: !!data?.temperatureSettings?.temperature?.show,
      defaultTemperature: data?.temperatureSettings?.temperature
        ?.defaultTemperature
        ? (TEMPERATURE_OPTIONS.find(
            (x) =>
              x.value ==
              data?.temperatureSettings?.temperature?.defaultTemperature
          ) as unknown as ISelectOptionType)
        : undefined,
      minTemperature: data?.temperatureSettings?.temperature?.minTemperature
        ? (TEMPERATURE_OPTIONS.find(
            (x) =>
              x.value == data?.temperatureSettings?.temperature?.minTemperature
          ) as unknown as ISelectOptionType)
        : undefined,
      maxTemperature: data?.temperatureSettings?.temperature?.maxTemperature
        ? (TEMPERATURE_OPTIONS.find(
            (x) =>
              x.value == data?.temperatureSettings?.temperature?.maxTemperature
          ) as unknown as ISelectOptionType)
        : undefined,
    },
    temperatureType: {
      show: !!data?.temperatureSettings?.temperatureType?.show,
      defaultTemperatureType: data?.temperatureSettings?.temperatureType
        ?.defaultTemperatureType
        ? (TEMPERATURE_UNIT_OPTIONS.find(
            (x) =>
              x.value ==
              data?.temperatureSettings?.temperatureType?.defaultTemperatureType
          ) as unknown as ISelectOptionType)
        : TEMPERATURE_UNIT_OPTIONS[0],
    },
  })

  // useEffect(setInitialFormData, []);
  const methods = useForm<Partial<IFormData>>({
    defaultValues: getInitialFormData(),
  });

  const { watch, setValue, handleSubmit, reset } = methods;

  const formData = watch();

  const onSubmit = (submitData : Partial<IFormData>) => {
    if (
      submitData.temperature!?.defaultTemperature!?.value <
      submitData.temperature!?.minTemperature!?.value ||
      submitData.temperature!?.defaultTemperature!?.value >
      submitData.temperature!?.maxTemperature!?.value
    ) {
      toast.error(
        "Default temperature should between min and max temperature"
      );
      return;
    }

    let newData = JSON.parse(JSON.stringify(submitData));
    for (const pKey in submitData) {
      if (Object.prototype.hasOwnProperty.call(submitData, pKey)) {
        const pkObj = submitData[pKey as keyof typeof submitData];
        for (const cKey in pkObj) {
          if (Object.prototype.hasOwnProperty.call(pkObj, cKey)) {
            const ckObj = pkObj[cKey as keyof typeof pkObj];
            if (typeof ckObj === "object") {
              // @ts-ignore
              newData[pKey as keyof typeof newData][cKey] =
                // @ts-ignore
                ckObj.value;
            } else {
              // @ts-ignore
              newData[pKey as keyof typeof newData][cKey] = ckObj;
            }
          }
        }
      }
    }
    onSave({
      categoryName: data?.categoryName,
      data: {
        ...data,
        temperatureSettings: {
          ...(data?.temperatureSettings || {}),
          ...newData,
        },
      },
    });
  }

  return (
    <FormProvider {...methods}>
      <div>
        <h3 className="text-[22px] font-normal text-[#1E2F65]">Temperature</h3>
        <div>
          {/* <Switch
            name="fanSpeed.show"
            label="Fan Speed (Hide/Show)"
          /> */}
          <h4 className="text-base leading-[39px] font-normal text-[#1E2F65] whitespace-nowrap">
            Fan Speed
          </h4>
          <div className="grid grid-cols-3 relative">
            <Select
              name="fanSpeed.defaultSpeed"
              label="Default Fan Speed"
              options={FAN_SPEED_OPTIONS}
            />
          </div>

          <div className="mt-[25px]">
            {/* <Switch
              name="temperature.show"
              label="Temperature (Hide/Show)"
              // checked={formData.temperature!?.show}
              // onCheck={(checked) =>
              //   updateFormData("temperature", "show", checked)
              // }
            /> */}
            <h4 className="text-base leading-[39px] font-normal text-[#1E2F65] whitespace-nowrap">
              Temperature
            </h4>
            <div className="grid grid-cols-3 gap-2 relative">
              <Select
                name="temperature.defaultTemperature"
                label="Default Temperature (°F)"
                options={TEMPERATURE_OPTIONS.filter((x) => {
                  if (
                    formData.temperature!?.minTemperature!?.value &&
                    formData.temperature!?.maxTemperature!?.value
                  )
                    return (
                      +x.value >=
                        +formData.temperature!?.minTemperature!?.value &&
                      +x.value <= +formData.temperature!?.maxTemperature!?.value
                    );
                  return true;
                })}
              />
              <Select
                name="temperature.minTemperature"
                label="Min Temperature (°F)"
                options={TEMPERATURE_OPTIONS}
                // value={formData.temperature!?.minTemperature}
                onChange={(newValue) => {
                  // updateFormData("temperature", "minTemperature", newValue);
                  if (
                    newValue.value >
                    formData.temperature!?.maxTemperature!?.value
                  ) {
                    setValue("temperature.maxTemperature", newValue);
                  }
                }}
              />
              <Select
                name="temperature.maxTemperature"
                label="Max Temperature (°F)"
                options={
                  formData.temperature!?.minTemperature!?.value
                    ? TEMPERATURE_OPTIONS.filter(
                        (x) =>
                          +x.value >=
                          +formData.temperature!?.minTemperature!?.value
                      )
                    : []
                }
                // value={formData.temperature!?.maxTemperature}
                onChange={(newValue) => {
                  // updateFormData("temperature", "maxTemperature", newValue);
                  if (
                    newValue.value <
                    formData.temperature!?.minTemperature!?.value
                  ) {
                    // updateFormData("temperature", "minTemperature", newValue);
                    setValue("temperature.minTemperature", newValue);
                  }
                }}
              />
            </div>
          </div>

          <div className="mt-[25px]">
            {/* <Switch
              name="temperatureType.show"
              label="Temperature Type (Hide/Show)"
            /> */}
            <h4 className="text-base leading-[39px] font-normal text-[#1E2F65] whitespace-nowrap">
              Temperature Type
            </h4>
            <div className="grid grid-cols-3 relative">
              <Select
                name="temperatureType.defaultTemperatureType"
                label=" Default Temperature type"
                options={TEMPERATURE_UNIT_OPTIONS}
              />
            </div>
          </div>

          <DiscardSaveButtons
            onDiscard={()=>reset(getInitialFormData())}
            onSave={handleSubmit(onSubmit)}
            loading={loading}
            className="mt-10"
          />
        </div>
      </div>
    </FormProvider>
  );
};

export default Temperature;
