import React, { useState } from "react";
import ReactDOM from "react-dom";
import Cropper from "react-easy-crop";
import classNames from "classnames";
import { CloseSvg } from "../../../icons";

interface ImageCropModalProps {
  id?: string;
  isOpen: boolean;
  image: File;
  onClose: () => void;
  onCropComplete: (croppedImage: Blob | null) => void;
  aspect?: number;
}

const ImageCropModal: React.FC<ImageCropModalProps> = ({
  id,
  isOpen,
  image,
  onClose,
  onCropComplete,
  aspect = 255 / 164,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    width: number;
    height: number;
    x: number;
    y: number;
  } | null>(null);

  const handleCropComplete = (
    _: any,
    croppedAreaPixels: {
      width: number;
      height: number;
      x: number;
      y: number;
    }
  ) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSave = async () => {
    if (image && croppedAreaPixels) {
      const croppedImage = await getCroppedImage(image, croppedAreaPixels);
      onCropComplete(croppedImage);
      onClose();
    }
  };

  const handleClose = () => {
    const fileInput = document.getElementById(id || 'tv-setup-img');
    if (fileInput) {
      (fileInput as HTMLInputElement).value = "";
    }
    onClose();
    onCropComplete(null);
  };

  const getCroppedImage = (
    img: string | File,
    crop: { x: number; y: number; width: number; height: number }
  ): Promise<Blob | null> => {
    return new Promise((resolve) => {
      const newImg = new Image();
      newImg.src = typeof img === "string" ? img : URL.createObjectURL(img);
      newImg.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) return resolve(null);

        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          newImg,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        canvas.toBlob((blob) => {
          resolve(blob);
        }, image.type);
      };
    });
  };

  if (!isOpen || !image) return null;

  return ReactDOM.createPortal(
    <div
      className={classNames(
        "fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      )}
    >
      <div
        className={classNames(
          "bg-white p-6 rounded-lg transform transition-all duration-300 ease-in-out w-[90%] max-w-[500px]"
        )}
      >
        <div className="flex justify-between items-center">
          <h2 className="text-xl leading-6 font-medium">Crop Image</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <CloseSvg />
          </button>
        </div>
        <div className="relative w-full h-64 bg-gray-200 mt-4">
          <Cropper
            image={
              typeof image === "string" ? image : URL.createObjectURL(image)
            }
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleCropComplete}
          />
        </div>
        <div className="flex justify-end mt-6 gap-2">
          <button
            className="px-4 py-2 bg-[#F4F4F4] text-black rounded"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-[#F4F4F4] text-black rounded"
            onClick={() => {
              setCrop({ x: 0, y: 0 });
              setZoom(1);
            }}
          >
            Reset
          </button>
          <button
            className="px-4 py-2 bg-[#F41095] text-white rounded"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ImageCropModal;
