import { useMutation } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseAddOffersHomeMutationResponse } from "../utils/types";

const useUpdateDraftOffer = (): IUseAddOffersHomeMutationResponse =>
  useMutation({
    mutationFn: async ({ data, id }: any) => {
      const response = await AXIOS_RESERVATION.put(
        `v1/coupons/draft/${id}`,
        data
      );
      return response.data;
    },
  });

export default useUpdateDraftOffer;
