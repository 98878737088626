import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import Button from "../../../../components/common/button";
import Switch from "../../../../components/common/Switch";
import Select from "../../../../components/common/Select";
import { ON_OFF_OPTIONS, TIME_OPTIONS } from "../../../../utils/constants";
import AddCalmingMusicModal from "../../../../components/tv-app/sleep-concierge/add-calming-music-modal";
import DeleteAndroidHome from "../../../../components/tv-app/delete-android-home";
import EditTableRowName from "../../../../components/tv-app/edit-name";
import DiscardSaveButtons from "../../../../components/tv-app/discard-save-buttons";
import {
  ICalmingMusicSubData,
  IDefaultOnOff,
  IMusicTime,
  ISleepCategoryComponentProps,
  ITableColumn,
} from "../../../../utils/types";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

type OmitIMusicTime = Omit<IMusicTime, "time"> & {
  time: { label: string; value: string } | undefined;
};

type IFormData = {
  showCM: boolean;
  defaultOnOrOff?: { label: string; value: IDefaultOnOff };
  data: ICalmingMusicSubData[];
  times: OmitIMusicTime[];
};

const CalmingMusic = ({
  subData,
  time,
  show,
  defaultOnOff,
  onSave,
  loading,
  categoryName,
  type,
}: ISleepCategoryComponentProps<ICalmingMusicSubData[]>) => {
  const getInitialFormData = () => {
    return {
      showCM: !!show,
      defaultOnOrOff: ON_OFF_OPTIONS.find(
        (item) => item.value === (defaultOnOff || "off")
      ),
      data: subData ?? [],
      times: (time ?? []).map((item) => ({
        ...item,
        time: TIME_OPTIONS.find((x) => x.value === item.time),
      })),
    };
  };

  const [InitialFormData, setInitialFormData] = useState<IFormData>(
    getInitialFormData()
  );

  const methods = useForm<IFormData>({ defaultValues: InitialFormData });
  const { reset, handleSubmit } = methods;

  const {
    fields: sleepCatagoryCMFields,
    append: appendSleepCatagoryCMField,
    remove: removeSleepCatagoryCMField,
  } = useFieldArray({ name: "data", control: methods.control });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (sleepCatagoryCMFields.length < subData.length) {
      const addedElement = subData[subData.length - 1];
      appendSleepCatagoryCMField({ ...addedElement });
      setInitialFormData((pre) => ({
        ...pre,
        data: [...pre.data, { ...addedElement }],
      }));
    }
  }, [subData]);

  const getTableDataIndex = (id: string) => {
    return sleepCatagoryCMFields.findIndex((item) => item.id === id);
  };

  const tableColumns: ITableColumn<ICalmingMusicSubData>[] = [
    {
      name: "Name",
      cell: (Row) => (
        <EditTableRowName name={`data.${getTableDataIndex(Row.id)}.name`} />
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (Row) => (
        <span className="text-[#666672]">
          {Row["defaultPlaying"] ? "Default" : ""}
        </span>
      ),
      center: true,
    },
    {
      name: "View",
      cell: (Row) => (
        <Switch name={`data.${getTableDataIndex(Row.id)}.selected`} />
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      cell: (Row) => (
        <DeleteAndroidHome
          categoryName={categoryName}
          type={type}
          deleteId={Row.id}
          removeField={() => {
            setInitialFormData((pre) => ({
              ...pre,
              data: pre.data.filter((item) => item.id !== Row.id),
            }));
            removeSleepCatagoryCMField(getTableDataIndex(Row.id));
          }}
        />
      ),
      sortable: true,
      center: true,
    },
  ];

  return (
    <FormProvider {...methods}>
      <div className="w-full mt-4">
        {/* <div className="">
          <Switch
            name="showCM"
            label="Calming Music  (Hide/Show)"
            // checked={showCM}
            // onCheck={(checked) => setShowCM(checked)}
          />
        </div> */}
        <h4 className="text-base leading-[39px] font-normal text-[#1E2F65] whitespace-nowrap">
          Calming Music
        </h4>
        <div className="grid grid-cols-3 gap-2 relative">
          <Select
            name="defaultOnOrOff"
            label="Default ON/OFF"
            options={ON_OFF_OPTIONS}
          />
          <Select
            name="times.0.time"
            label="Default Start Time"
            options={TIME_OPTIONS}
          />
          <Select
            name="times.1.time"
            label="Default End Time"
            options={TIME_OPTIONS}
          />
        </div>
        <div className="flex items-center justify-between w-full mt-7.5">
          <h4 className="text-base leading-[39px] font-normal text-[#1E2F65]">
            Music List
          </h4>
          <Button variant="primary" className="w-21" onClick={openModal}>
            Add
          </Button>
        </div>
        {/* <div className="h-full max-h-[calc(100vh-620px)] overflow-auto mt-[17px]"> */}
        <DataTable
          className="border-t mt-6"
          data={sleepCatagoryCMFields}
          // @ts-ignore
          columns={tableColumns}
          striped={true}
          keyField="customId"
          persistTableHead
          pagination={sleepCatagoryCMFields.length > 10}
          customStyles={{
            headRow: { style: { paddingLeft: "36px" } },
            rows: { style: { paddingLeft: "36px" } },
          }}
        />
        {/* </div> */}

        <DiscardSaveButtons
          onDiscard={() => {
            reset(getInitialFormData());
          }}
          onSave={handleSubmit((submitData: IFormData) => {
            const times = submitData.times
              .map((t) => ({
                ...t,
                time: t?.time?.value,
              }))
              .filter((t): t is IMusicTime => t.time !== undefined);

            onSave({
              show: submitData.showCM,
              subData: submitData.data,
              time: times,
              defaultOnOff: submitData.defaultOnOrOff?.value,
            });
          })}
          loading={loading}
          className="mt-10"
        />

        <AddCalmingMusicModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          categoryName={categoryName}
          type={type}
        />
      </div>
    </FormProvider>
  );
};

export default CalmingMusic;
