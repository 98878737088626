import React from "react";
import Button from "../../../../components/common/button";
import { CustomOfferSvg, LeftArrowSvg } from "../../../../icons";
import { Link, useParams } from "react-router-dom";
import useGetOfferTemplatesQuery from "../../../../hooks/use-get-offer-templates";
import Spinner from "../../../../components/common/Spinner";

const OfferTemplates: React.FC = () => {
  const params = useParams();
  const { data: offerTemplatesData, isLoading: offerTemplatesDataLoading } =
    useGetOfferTemplatesQuery();

  return (
    <div>
      <div className="">
        <div className="flex gap-2 items-center">
          <Link to={`${process.env.PUBLIC_URL}/shmv/tv-app/offers/offers`}>
            <LeftArrowSvg />
          </Link>
          <h3 className="text-[22px] font-normal text-[#1E2F65]">
            Offers Management
          </h3>
        </div>
        <h2 className="text-[14px] mt-9 font-normal text-[#1E2F65]">
          Create an offer
        </h2>
        <h3 className="text-[12px] font-normal text-[#1E2F65] mt-[22px]">
          Select Template
        </h3>
      </div>
      {offerTemplatesDataLoading ? (
        <div className="w-full flex items-center justify-center min-h-32">
          <Spinner />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-5 mt-6">
          {offerTemplatesData?.result?.data?.map((template, index) => (
            <div
              key={template._id}
              className="border shadow-lg hover:shadow-lg transition duration-300"
            >
              <img
                src={template.tvSetupImage}
                alt={template.name}
                className="w-full h-40 object-cover mb-4"
              />
              <div className="p-2">
                <h5 className="text-[12px] font-semibold ">{template.name}</h5>
                <p className="text-[10px] text-gray-500 my-2">
                  {template.description}
                </p>
                <Link
                  to={`/${params.hotelcode}/tv-app/offers/offers/create?templateId=${template._id}`}
                >
                  <Button className="w-full text-black bg-white border border-black text-[12px] font-medium py-2 hover:bg-black hover:text-white transition">
                    Select
                  </Button>
                </Link>
              </div>
            </div>
          ))}
          <Link
            to={`/${params.hotelcode}/tv-app/offers/offers/create`}
            className="flex flex-col justify-center items-center  border-gray-300 p-6 rounded-lg bg-gray-50 shadow-sm hover:shadow-lg transition duration-300"
          >
            <div className="bg-[#D9D9D9] w-[40px] h-[40px] flex justify-center items-center rounded-full ">
              <CustomOfferSvg />
            </div>
            <h5 className="text-[12px] mt-4 font-semibold ">
              Create a Custom Offer
            </h5>
          </Link>
        </div>
      )}
    </div>
  );
};

export default OfferTemplates;
