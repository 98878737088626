import classNames from "classnames";
import { ReactNode, useEffect } from "react";
import { FieldError, RegisterOptions, useController } from "react-hook-form";
import { getNestedError } from "../../../utils/helpers";

type ISwitchProps = {
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
  label?: string | ReactNode;
  name?: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  disabled?: boolean;
};

const Switch = ({
  checked,
  onCheck,
  label,
  name,
  rules,
  disabled,
}: ISwitchProps) => {
  // Using useController to get the field and form state errors
  const {
    field,
    formState: { errors },
  } = useController({ name: name || "", rules });

  useEffect(() => {
    if (typeof checked === "boolean") field.onChange(checked);
  }, [checked]);

  return (
    <div className="flex items-center gap-2">
      {label ? (
        typeof label === "string" ? (
          <h4 className="text-base leading-[39px] font-normal text-[#1E2F65] whitespace-nowrap">
            {label}
          </h4>
        ) : (
          label
        )
      ) : null}
      <div
        className={classNames(
          "relative h-4 min-w-7 rounded-lg px-[3px] transition-all ease-in-out",
          field.value ? "bg-[#00B519]" : "bg-[#F4F4F4]",
          disabled ? "opacity-50" : "cursor-pointer"  
        )}
        onClick={
          disabled
            ? undefined
            : () =>
                onCheck ? onCheck(field.value) : field.onChange(!field.value)
        }
      >
        <div
          className={classNames(
            "p-1.5 rounded-full bg-white transition-all ease-in-out absolute top-1/2 -translate-y-1/2",
            field.value ? "translate-x-[11px]" : "translate-x-0"
          )}
        />
      </div>
      {errors && name && (
        <span className="text-red-500 text-sm">
          {/* This line casts the error to FieldError and retrieves its message */}
          {(getNestedError(errors, name) as FieldError)?.message}
        </span>
      )}
    </div>
  );
};

export default Switch;
