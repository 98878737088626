import { useState } from "react";
import { EditSvg } from "../../../icons";
import Modal from "../Modal";
import CreateOffer from "../../../pages/tv-app/offers/offers/create-offers";
import { IOffer } from "../../../utils/types";
import classNames from "classnames";

interface EditOfferProps {
  data: IOffer | null;
  showIcon?: boolean;
  defaultOpen?: boolean;
  onClose?: () => void;
  isDraftEditOffer?: boolean;
  disabled?: boolean;
}
const EditOffer: React.FC<EditOfferProps> = ({
  data,
  showIcon = true,
  defaultOpen = false,
  onClose,
  isDraftEditOffer,
  disabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(defaultOpen);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    onClose?.();
  };

  return (
    <>
      {showIcon && (
        <div
          onClick={disabled ? undefined : openModal}
          className={classNames(
            "h-6 w-6 rounded-[3.63px] bg-[rgba(99,98,231,0.2)] flex items-center justify-center",
            disabled ? "opacity-50" : "cursor-pointer"
          )}
        >
          <EditSvg color="#27e5bc" />
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title={isDraftEditOffer ? "Edit Draft Offer" : "Edit Offer"}
        headerClassName="px-6"
        modelContentClassName="mt-1"
        className="w-[60%] mx-auto rounded-lg bg-white shadow-lg p-0 pb-6"
      >
        <div className="max-h-[75vh] overflow-y-auto p-6 pt-0">
          <CreateOffer
            offer={data!}
            isEditOffer={!isDraftEditOffer}
            onSuccess={() => closeModal()}
            isDraftEditOffer={isDraftEditOffer}
          />
        </div>
      </Modal>
    </>
  );
};

export default EditOffer;
