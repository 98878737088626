import { useState } from "react";
import {
  AmbientSceneSvg,
  RelexSceneSvg,
  EnergiseSceneSvg,
  CoolBrightSceneSvg,
  BlueSceneSvg,
  PinkSceneSvg,
  DragSvg,
} from "../../../icons";
import classNames from "classnames";
// import Switch from "../../../components/common/Switch";
import SortableList from "../../../components/common/SortableList";
import DiscardSaveButtons from "../../../components/tv-app/discard-save-buttons";
import { ICategoryComponentProps } from "../../../utils/types";
import { FormProvider, useForm } from "react-hook-form";

const SCENES = [
  {
    id: 1,
    name: "Ambient",
    icon: AmbientSceneSvg,
  },
  {
    id: 2,
    name: "Relax",
    icon: RelexSceneSvg,
  },
  {
    id: 3,
    name: "Energise",
    icon: EnergiseSceneSvg,
  },
  {
    id: 4,
    name: "Cool Bright",
    icon: CoolBrightSceneSvg,
  },
  {
    id: 5,
    name: "Blue",
    icon: BlueSceneSvg,
  },
  {
    id: 6,
    name: "Pink",
    icon: PinkSceneSvg,
  },
];

type Color = {
  id: string;
  name: string;
  hex: string;
  red: string;
  green: string;
  blue: string;
};

type IFormData = {
  showAdditionalColors: boolean;
  colors: Color[];
};

const Light = ({ data, onSave, loading }: ICategoryComponentProps) => {
  const [scenes, setScenes] = useState(SCENES);

  const getInitialFormData = () => ({
    showAdditionalColors: Boolean(data?.lightColors?.showAdditionalColors),
    colors: data?.lightColors?.additionalColors || [],
  });

  const methods = useForm<IFormData>({
    defaultValues: getInitialFormData(),
  });

  const { reset, handleSubmit, watch, setValue } = methods;

  const formData = watch();

  const onSubmit = (submitData: IFormData) => {
    onSave({
      categoryName: data?.categoryName,
      data: {
        ...data,
        lightColors: {
          ...data?.lightColors,
          additionalColors: [...submitData.colors],
          showAdditionalColors: submitData.showAdditionalColors,
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <div>
        <h3 className="text-[22px] font-normal text-[#1E2F65]">Light</h3>
        <div>
          {/* <div className="mt-27">
            <Switch
              name="showAdditionalColors"
              label="Additional Colors (Hide/Show)"
            />
          </div> */}
          <h4 className="text-base leading-[39px] font-normal text-[#1E2F65] whitespace-nowrap">
            Additional Colors
          </h4>
          <div className="flex items-center gap-5 flex-wrap mt-[16.2px] relative">
            {formData.colors.map((color, i) => (
              <label
                key={color.id}
                className={classNames(
                  "h-[30px] w-[30px] rounded-full shadow-[0px_4px_4px_0px_#0000001A] relative cursor-pointer"
                )}
                style={{ backgroundColor: color.hex }}
                htmlFor={`color-${i}`}
              >
                <input
                  id={`color-${i}`}
                  type="color"
                  className="invisible w-[30px] absolute left-0"
                  value={color.hex}
                  onChange={(e) => {
                    let temp = [...formData.colors];
                    temp[i] = {
                      ...temp[i],
                      hex: e.target.value,
                      red: parseInt(e.target.value.slice(1, 3), 16).toString(),
                      green: parseInt(
                        e.target.value.slice(3, 5),
                        16
                      ).toString(),
                      blue: parseInt(e.target.value.slice(5, 7), 16).toString(),
                    };
                    setValue(`colors`, temp);
                    // setColors(temp);
                  }}
                />
              </label>
            ))}
          </div>

          {/* <div className="mt-9">
            <Switch
              name="checked"
              label="Scenes (Hide/Show)"
            />
          </div> */}
          <h4 className="text-base leading-[39px] mt-9 font-normal text-[#1E2F65] whitespace-nowrap">
            Scenes
          </h4>

          <div className="flex items-center flex-wrap gap-[17px] mt-[16.2px] relative">
            <SortableList
              direction="horizontal"
              sortableArray={scenes}
              onSort={(newArray) => {
                setScenes(newArray);
              }}
            >
              {scenes.map((scene) => (
                <div
                  key={scene.id}
                  className="min-w-[76px] max-h-[106px] bg-white shadow-[0px_4px_30px_0px_#5F5EE712] flex items-center flex-col justify-around pt-[13px] pb-1.5"
                >
                  <div style={{ minHeight: "30px", minWidth: "30px" }}>
                    <scene.icon />
                  </div>
                  <p className="text-[11.93px] leading-[31.89px] font-light text-[#535353]">
                    {scene.name}
                  </p>
                  <DragSvg className="" height={11} width={11} />
                </div>
              ))}
            </SortableList>
          </div>

          <DiscardSaveButtons
            onDiscard={() => {
              reset(getInitialFormData());
            }}
            onSave={handleSubmit(onSubmit)}
            loading={loading}
            className="mt-10"
          />
        </div>
      </div>
    </FormProvider>
  );
};

export default Light;
