import CurrentOffer from "./offers/current-offers";

const Offers: React.FC = () => {
  return (
    <div>
      <div className="mt-1">
      <h3 className="text-[22px] font-normal text-[#1E2F65]">Offers Management</h3>
        <CurrentOffer />
      </div>
    </div>
  );
};

export default Offers;
