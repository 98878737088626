import moment from "moment-timezone";
import Select from "../../../../components/common/Select";

import TextInput from "../../../../components/common/TextInput";
import DiscardSaveButtons from "../../../../components/tv-app/discard-save-buttons";
import TextArea from "../../../../components/common/TextArea";
import DatePicker from "../../../../components/common/DatePicker";
import FileInput from "../../../../components/common/FileInput";
import TimeInput from "../../../../components/common/TimeInput";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import useAddOffer from "../../../../hooks/use-add-offer";
import {
  cleanObject,
  errorToast,
  objectToFormData,
  successToast,
} from "../../../../utils/helpers";
import useGetCouponTypesQuery from "../../../../hooks/use-get-coupon-types";
import useGetCostCentersDataQuery from "../../../../hooks/use-get-cost-centers-data";
import useGetEligibilityCriteriaQuery from "../../../../hooks/use-get-eligibility-criteria";
import useGetBusinessReasonsDataQuery from "../../../../hooks/use-get-business-reasons";
import { queryClient } from "../../../../App";
import {
  AddEligibilitySvg,
  LeftArrowSvg,
  RemoveEligibilitySvg,
} from "../../../../icons";
import { useEffect, useState } from "react";
import ImageCropModal from "../../../../components/common/CropImage";
import { ApiResponse, IOffer, IOfferTemplate } from "../../../../utils/types";
import useUpdateOffer from "../../../../hooks/use-update-offer";
import Spinner from "../../../../components/common/Spinner";
import DynamicMultiSelect from "../../../../components/common/DynamicMultiSelect";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Button from "../../../../components/common/button";
import useAddDraftOffer from "../../../../hooks/use-add-draft-offer";
import useUpdateDraftOffer from "../../../../hooks/use-update-draft-offer";

type SelectValue = { label?: string; value?: string } | null | undefined;

interface ICreateOfferFormValues {
  id?: string;
  costCenter: SelectValue;
  costSubCenter?: SelectValue;
  name: string;
  description: string;
  redemptionDescription: string;
  businessReason: SelectValue;
  redemptionLocation: SelectValue;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  tvSetup: {
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    image: Blob | string | null;
  };
  type: SelectValue;
  value?: number | null;
  cost?: number | null;
  eligibilityCriterias: {
    eligibilityCriteria: {
      label: string;
      value: string;
    } | null;
    data:
      | { label: string; value: string }
      | { label: string; value: string }[]
      | string
      | undefined;
  }[];
}

const RESERVATION_NO = "Reservation No";
const DynamicMultiSelectKeys = ["RESERVATION_NO"];

const CreateOffer = ({
  offer = {},
  isEditOffer = false,
  onSuccess,
  isDraftEditOffer,
}: {
  offer?: Partial<IOffer>;
  isEditOffer: boolean;
  onSuccess?: () => void;
  isDraftEditOffer?: boolean;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [queryParams] = useSearchParams();
  const templateId = queryParams.get("templateId");

  const { data: costCentersData, isLoading: costCenterLoading } =
    useGetCostCentersDataQuery();
  const { data: couponTypesData, isLoading: couponTypesDataLoading } =
    useGetCouponTypesQuery();
  const {
    data: eligibilityCriteriaData,
    isLoading: eligibilityCriteriaLoading,
  } = useGetEligibilityCriteriaQuery();
  const { data: businessReasonData, isLoading: businessReasonDataLoading } =
    useGetBusinessReasonsDataQuery();

  const [disableEligibilityFieldsAddOn] = useState<string[]>([RESERVATION_NO]);
  const [hideDatesFor] = useState<string[]>([RESERVATION_NO]);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);

  const formatLocalDate = (date?: string | Date): string =>
    date ? new Date(date).toISOString().split("T")[0] : "";

  const formatDateToUsPecificTimezone = (date: string) => {
    return moment
      .tz(date || moment(), "America/Los_Angeles")
      .format("YYYY-MM-DD");
  };

  const formatTimeToUsPecificTimezone = (date: string) => {
    return moment.tz(date || moment(), "America/Los_Angeles").format("HH:mm");
  };

  const [template, setTemplate] = useState<IOfferTemplate | null>(null);
  const [defaultValues, setDefaultValues] = useState<ICreateOfferFormValues>({
    costCenter: offer?.costCenter
      ? {
          label: costCentersData?.result?.data.find(
            (x) => x._id === offer?.costCenter
          )?.name,
          value: offer?.costCenter,
        }
      : null,
    costSubCenter: null,
    name: offer?.name || "",
    description: offer?.description || "",
    redemptionDescription: offer?.redemptionDescription || "",
    businessReason: null,
    redemptionLocation: null,
    startDate: formatDateToUsPecificTimezone(offer?.startDate!),
    endDate: formatDateToUsPecificTimezone(offer?.endDate!),
    startTime: formatTimeToUsPecificTimezone(offer?.startTime!),
    endTime: offer?.endTime
      ? formatTimeToUsPecificTimezone(offer?.endTime)
      : "23:59",
    tvSetup: {
      startDate: formatDateToUsPecificTimezone(offer?.startDate!),
      endDate: formatDateToUsPecificTimezone(offer?.endDate!),
      startTime: formatTimeToUsPecificTimezone(offer?.startTime!),
      endTime: offer?.endTime
        ? formatTimeToUsPecificTimezone(offer?.endTime)
        : "23:59",
      image: offer?.tvSetup?.image as string,
    },
    type: offer?.type
      ? {
          label: couponTypesData?.result?.data.find(
            (x) => x._id === offer?.type
          )?.name,
          value: offer.type,
        }
      : null,
    value: offer?.value || null,
    cost: offer?.cost !== undefined ? offer.cost : null,
    eligibilityCriterias: [{ eligibilityCriteria: null, data: "" }],
  });

  const { mutate: addOffer, isPending: loading } = useAddOffer();
  const { mutate: updateOffer, isPending: updateLoading } = useUpdateOffer({
    id: offer._id,
  });
  const { mutate: addDraftOffer, isPending: addDraftOfferLoading } =
    useAddDraftOffer();
  const { mutate: updateDraftOffer, isPending: updateDraftOfferLoading } =
    useUpdateDraftOffer();

  const clearValues = () => {
    methods.reset({ ...defaultValues });
  };
  const onSubmit = (data: ICreateOfferFormValues) => {
    let payload: any = {
      ...data,
      hotelId: "c102",
      type: offer.type || data.type?.value,
      businessReason: data.businessReason?.value,
      costCenter: data.costCenter?.value,
      costSubCenter: data.costSubCenter?.value,
      redemptionLocation: data.redemptionLocation?.value,
      cost: data.cost,
      value: offer.value || data.value,
      eligibilityCriterias: data.eligibilityCriterias.map((x) => ({
        criteriaId: x.eligibilityCriteria?.value,
        ...(x.data
          ? {
              values: Array.isArray(x.data)
                ? x.data.map((y: any) => y.value)
                : typeof x.data === "string"
                ? [x.data]
                : [(x.data as SelectValue)?.value],
            }
          : {}),
      })),
    };

    if (typeof payload.tvSetup.image === "string" && !isDraftEditOffer) {
      payload.tvSetup.image = null;
    }

    if (isDraftEditOffer) {
      payload["draftCouponId"] = offer._id;
    }

    if (template) {
      const { startDate, endDate, startTime, endTime, tvSetup } = payload;
      delete tvSetup.image;
      payload = { templateId, startDate, endDate, startTime, endTime, tvSetup };
    }

    cleanObject(payload);

    if (isEditOffer) {
      updateOffer(objectToFormData(payload), {
        onSuccess: async (res) => {
          successToast(res.result.message);
          handleImageClear();
          onSuccess?.();
        },
        onError: (err) => {
          errorToast(err?.response?.data?.result?.message);
        },
      });
    } else {
      addOffer(objectToFormData(payload), {
        onSuccess: async (res) => {
          successToast(res.result.message);
          await queryClient.refetchQueries({ queryKey: ["offers"] });
          handleImageClear();
          const fileInput = document.getElementById(id || "tv-setup-img");
          if (fileInput) {
            (fileInput as HTMLInputElement).value = "";
          }
          clearValues();

          if (isDraftEditOffer) onSuccess?.();
          else navigate(`/${params.hotelCode}/tv-app/offers/offers`);
        },
        onError: (err) => {
          errorToast(err?.response?.data?.result?.message);
        },
      });
    }
  };

  const methods = useForm<ICreateOfferFormValues>({
    defaultValues,
  });
  const {
    fields: eligibilityCriteriaFields,
    append: appendEligibilityCriteria,
    remove: removeEligibilityCriteria,
  } = useFieldArray({
    control: methods.control,
    name: "eligibilityCriterias", // unique name for your Field Array
  });
  const { setValue, watch, handleSubmit } = methods;
  const {
    id,
    costCenter,
    // costSubCenter,
    // name,
    // description,
    // redemptionDescription,
    // businessReason,
    // redemptionLocation,
    startDate,
    endDate,
    startTime,
    // endTime,
    tvSetup,
    type,
    value,
    cost,
    eligibilityCriterias,
  } = watch();

  const saveAsDraft = () => {
    const data = watch();
    let payload: any = {
      ...data,
      type: data.type?.value,
      businessReason: data.businessReason?.value,
      costCenter: data.costCenter?.value,
      costSubCenter: data.costSubCenter?.value,
      redemptionLocation: data.redemptionLocation?.value,
      eligibilityCriterias: data.eligibilityCriterias.map((x) => ({
        criteriaId: x.eligibilityCriteria?.value,
        ...(x.data
          ? {
              values: Array.isArray(x.data)
                ? x.data.map((y: any) => y.value)
                : typeof x.data === "string"
                ? [x.data]
                : [(x.data as SelectValue)?.value],
            }
          : {}),
      })),
    };

    if (typeof payload.tvSetup.image === "string") {
      payload.tvSetup.image = null;
    }

    cleanObject(payload);
    if (isDraftEditOffer) {
      updateDraftOffer(
        { data: objectToFormData(payload), id: offer._id },
        {
          onSuccess: async (res) => {
            successToast(res.result.message);
            onSuccess?.();
          },
          onError: (err) => {
            errorToast(err?.response?.data?.result?.message);
          },
        }
      );
    } else {
      addDraftOffer(objectToFormData(payload), {
        onSuccess: async (res) => {
          successToast(res.result.message);
          onSuccess?.();
          navigate(`/${params.hotelCode}/tv-app/offers/offers`);
        },
        onError: (err) => {
          errorToast(err?.response?.data?.result?.message);
        },
      });
    }
  };

  const isDisabled = !!isEditOffer;
  // OLD
  // const isDisabled = !!(isEditOffer || template);
  // const isDisabledWhenTemplate = isDisabled && !!template;

  const costCenterOptions =
    costCentersData?.result?.data.map((x) => ({
      label: x.name,
      value: x._id,
    })) || [];

  const subCentersOptions =
    costCentersData?.result?.data
      ?.find((x) => x._id === costCenter?.value)
      ?.subCenters?.map((x) => ({
        label: x.name,
        value: x._id,
      })) || [];

  const redemptionLocationOptions =
    costCentersData?.result?.data
      .map((x) => x?.subCenters || [])
      .flat()
      ?.map((x) => ({
        label: x.name,
        value: x._id,
      })) || [];

  const offerTypeOptions =
    couponTypesData?.result?.data.map((x) => ({
      label: x.name,
      value: x._id,
    })) || [];

  const selectedCouponType = couponTypesData?.result?.data.find(
    (x) => x._id === type?.value
  );

  const [valueType, setValueType] = useState<string | undefined>(
    selectedCouponType?.valueType
  );

  useEffect(() => {
    if (templateId) {
      const templatesQueryData = queryClient.getQueryData([
        "offer-templates",
      ]) as ApiResponse<IOfferTemplate[]>;
      const templateCache = templatesQueryData!?.result!?.data?.find(
        (x) => x._id === templateId
      );
      if (templateCache) setTemplate(templateCache);
    }
  }, [templateId]);

  useEffect(() => {
    if (selectedCouponType) {
      setValueType(selectedCouponType.valueType);
    }
  }, [selectedCouponType]);

  const eligibilityOptions =
    eligibilityCriteriaData?.result?.data
      .sort((b, a) => b.viewRank - a.viewRank)
      .map((x) => ({
        label: x.name,
        value: x._id,
      })) || [];

  const [dynamicOptionsReset, setdynamicOptionsReset] = useState(true);

  const handleDynamicOptionsReset = () => {
    setdynamicOptionsReset(false);
  };

  const businessReasonOptions =
    businessReasonData?.result?.data.map((x) => ({
      label: x.name,
      value: x._id,
    })) || [];

  useEffect(() => {
    if (
      !costCenterLoading &&
      !couponTypesDataLoading &&
      !eligibilityCriteriaLoading &&
      !businessReasonDataLoading &&
      (isEditOffer || template || isDraftEditOffer)
    ) {
      let eligibilityFormData: {
        eligibilityCriteria: {
          label: string;
          value: string;
        } | null;
        data:
          | { label: string; value: string }
          | string
          | undefined
          | { label: string; value: string }[];
      }[] = [];
      (template || offer).eligibilityCriterias?.map((eligibility) => {
        const selectedEligibility = eligibilityCriteriaData?.result?.data.find(
          (x) => x._id === eligibility.criteriaId
        );

        const valueTypeEligibility = DynamicMultiSelectKeys.includes(
          selectedEligibility?.key!
        )
          ? "DYNAMIC_MULTI_SELECT"
          : selectedEligibility?.valueType;

        if (
          selectedEligibility &&
          valueTypeEligibility === "DYNAMIC_MULTI_SELECT"
        ) {
          const values = eligibility.values.map((value: any) => ({
            label: value,
            value: value,
          }));
          eligibilityFormData.push({
            eligibilityCriteria: {
              label: selectedEligibility?.name,
              value: selectedEligibility?._id,
            },
            data: values,
          });
        } else if (
          selectedEligibility &&
          valueTypeEligibility === "SINGLE_SELECT"
        ) {
          const values = {
            label: selectedEligibility?.options?.find(
              (x) => x.value === eligibility.values[0]
            )?.label,
            value: eligibility.values[0],
          };

          eligibilityFormData.push({
            eligibilityCriteria: {
              label: selectedEligibility?.name,
              value: selectedEligibility?._id,
            },
            data: values,
          });
        } else if (
          selectedEligibility &&
          valueTypeEligibility === "MULTI_SELECT"
        ) {
          const values = selectedEligibility?.options
            ?.filter((x) => eligibility.values.includes(x.value))
            .map((x) => ({ label: x.label, value: x.value }));
          eligibilityFormData.push({
            eligibilityCriteria: {
              label: selectedEligibility?.name,
              value: selectedEligibility?._id,
            },
            data: values,
          });
        } else if (selectedEligibility && valueTypeEligibility === "TEXT") {
          const values = eligibility.values[0];
          eligibilityFormData.push({
            eligibilityCriteria: {
              label: selectedEligibility?.name,
              value: selectedEligibility?._id,
            },
            data: values,
          });
        } else if (selectedEligibility && valueTypeEligibility === "NONE") {
          eligibilityFormData.push({
            eligibilityCriteria: {
              label: selectedEligibility?.name,
              value: selectedEligibility?._id,
            },
            data: undefined,
          });
        }
      });

      // This subcost center is using while creating offer from template
      const subCentersOptions =
        costCentersData?.result?.data
          ?.find(
            (x) =>
              x._id === (costCenter?.value || (template || offer)?.costCenter)
          )
          ?.subCenters?.map((x) => ({
            label: x.name,
            value: x._id,
          })) || [];

      const defaultFormValuesAfterFetch = {
        costCenter: (template || offer)?.costCenter
          ? {
              label: costCentersData?.result?.data.find(
                (x) => x._id === (template || offer)?.costCenter
              )?.name,
              value: (template || offer)?.costCenter,
            }
          : null,
        costSubCenter: (template || offer)?.costSubCenter
          ? subCentersOptions.find(
              (x) => x.value === (template || offer)?.costSubCenter
            )
          : null,
        name: (template || offer)?.name || "",
        description: (template || offer)?.description || "",
        redemptionDescription: (template || offer)?.redemptionDescription || "",
        businessReason: (template || offer)?.businessReason
          ? businessReasonOptions.find(
              (x) => x.value === (template || offer)?.businessReason
            )
          : null,
        redemptionLocation: (template || offer)?.redemptionLocation
          ? redemptionLocationOptions.find(
              (x) => x.value === (template || offer)?.redemptionLocation
            )
          : null,
        startDate: offer?.startDate
          ? formatLocalDate(offer?.startDate)
          : new Date().toISOString().split("T")[0],
        endDate: offer?.endDate
          ? formatLocalDate(offer?.endDate)
          : new Date().toISOString().split("T")[0],
        startTime:
          offer?.startTime ||
          new Date().toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }),
        endTime: offer?.endTime || "23:59",
        tvSetup: {
          startDate: offer?.tvSetup?.startDate
            ? formatLocalDate(offer?.tvSetup?.startDate)
            : new Date().toISOString().split("T")[0],
          endDate: offer?.tvSetup?.endDate
            ? formatLocalDate(offer?.tvSetup?.endDate)
            : new Date().toISOString().split("T")[0],
          startTime:
            offer?.tvSetup?.startTime ||
            new Date().toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }),
          endTime: offer?.tvSetup?.endTime || "23:59",
          image: template?.tvSetupImage || offer?.tvSetup?.image || null,
        },
        type: (template || offer).type
          ? offerTypeOptions.find((x) => x.value === (template || offer).type)
          : null,
        value: (template || offer)?.value || null,
        cost: (template || offer)?.cost || null,
        eligibilityCriterias: eligibilityFormData.length
          ? eligibilityFormData
          : isDraftEditOffer
          ? [{ eligibilityCriteria: null, data: "" }]
          : [],
      };
      setDefaultValues(defaultFormValuesAfterFetch);
      methods.reset(defaultFormValuesAfterFetch);
    }
  }, [
    costCenterLoading,
    couponTypesDataLoading,
    eligibilityCriteriaLoading,
    businessReasonDataLoading,
    isEditOffer,
    isDraftEditOffer,
    template,
  ]);

  const validateEndTime =
    (startDate: string, endDate: string, startTime: string) =>
    (value: string) => {
      if (!startDate || !endDate || !startTime) return true;
      if (startDate !== endDate) return true;
      const [startHours, startMinutes] = startTime.split(":");
      const [endHours, endMinutes] = value.split(":");
      switch (true) {
        case startHours > endHours:
          return "End time should be greater than start time";
        case startHours === endHours && startMinutes > endMinutes:
          return "End time should be greater than start time";
        case startHours === endHours && startMinutes === endMinutes:
          return "End time and start time can not be same";
        default:
          return true; // Valid case
      }
    };

  const eligibilityCriteriaAppendField = () => {
    appendEligibilityCriteria({
      eligibilityCriteria: null,
      data: "",
    });
  };

  const eligibilityCriteriarRemoveField = (index: number) => {
    removeEligibilityCriteria(index);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      setImageFile(file);
      setIsCropModalOpen(true);
    }
  };

  const handleCropComplete = (croppedImageBlob: Blob | null) => {
    if (croppedImageBlob) {
      const file = new File([croppedImageBlob], imageFile?.name!, {
        type: croppedImageBlob?.type,
        lastModified: imageFile?.lastModified,
      });
      setValue("tvSetup.image", file);
    }
    setIsCropModalOpen(false);
  };

  const handleImageClear = () => {
    setImageFile(null);
    setValue("tvSetup.image", null);
  };

  const CouponTypeKEY = selectedCouponType?.key;

  return (
    <FormProvider {...methods}>
      {costCenterLoading ||
      couponTypesDataLoading ||
      eligibilityCriteriaLoading ||
      businessReasonDataLoading ? (
        <Spinner fullScreenOverlay className="z-[100]" />
      ) : null}
      <div>
        {!isEditOffer && !isDraftEditOffer && (
          <div className="flex gap-2 items-center">
            <Link
              to={`${process.env.PUBLIC_URL}/shmv/tv-app/offers/offers/templates`}
            >
              <LeftArrowSvg />
            </Link>
            <h3 className="text-[22px] font-normal text-[#1E2F65]">
              Offers Management
            </h3>
          </div>
        )}
        <div className="grid grid-cols-1 relative mt-5">
          <TextInput
            name="name"
            rules={{
              required: "Offer Name is required",
              maxLength: {
                value: 30,
                message: "Offer Name cannot exceed 30 characters",
              },
            }}
            label="Offer Name"
            placeholder="$20 F&B Credit on all Outlets"
          />
        </div>
        <div className="mt-5">
          <TextArea
            name="description"
            rules={{
              required: "Offer Description is required",
              maxLength: {
                value: 110,
                message: "Offer Description cannot exceed 110 characters",
              },
            }}
            label="Offer Description"
            placeholder="Enjoy a $20 Food & Beverage credit at all outlets! Plus, get a free drink exclusively when using Shashi Cash. 
        Don't miss out on this special offer!"
            className="w-full h-[75px] p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400 font-sans placeholder:text-[14px]"
          />
        </div>
        <div className="mt-5">
          <TextArea
            name="redemptionDescription"
            rules={{
              required: "Redemption description is required",
              maxLength: {
                value: 100,
                message: "Redemption description cannot exceed 100 characters",
              },
            }}
            label="Redemption description"
            placeholder="Must be charged to your room. Discount applied automatically at checkout."
            className="w-full h-[75px] p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400 font-sans placeholder:text-[14px]"
          />
        </div>

        <div className="mt-5 grid grid-cols-3 gap-5">
          <Select
            name="costCenter"
            rules={{ required: "Cost Center is required" }}
            label="Cost Center"
            placeholder="Select Cost Center"
            // value={costCenter}
            options={costCenterOptions}
            onChange={(data) => {
              setValue("costSubCenter", null);
              setValue("costCenter", data);
            }}
            isDisabled={isDisabled}
          />
          <Select
            name="costSubCenter"
            rules={{ required: "Cost Sub Center is required" }}
            label="Select Value"
            placeholder={
              costCenter ? `Select ${costCenter.label}` : "Select Value"
            }
            isDisabled={isDisabled}
            options={subCentersOptions}
          />
        </div>

        <div className="mt-5 grid grid-cols-3 gap-5">
          <Select
            name="type"
            rules={{ required: "Type is required" }}
            label="Offer Type"
            placeholder="Select Offer Type"
            options={offerTypeOptions}
            value={type}
            onChange={(data) => {
              setValue("type", data);
              setValue("value", null);
              setValue("cost", null);
            }}
            isDisabled={isDisabled}
          />

          <TextInput
            name="value"
            rules={{ required: "Value is required" }}
            label="Enter Value"
            placeholder={`Enter ${
              CouponTypeKEY === "PERCENTAGE" || CouponTypeKEY === "FIXED_AMOUNT"
                ? `${type?.label ?? "Type"}`
                : "Value"
            }`}
            value={
              value
                ? CouponTypeKEY === "PERCENTAGE"
                  ? `${value}%`
                  : `$${value}`
                : ""
            }
            onChange={(e) => {
              const inputValue = e.target.value.replace(
                CouponTypeKEY === "PERCENTAGE" ? /%/g : /\$/g,
                ""
              );
              setValue("value", +inputValue);
              setValue("cost", null);
            }}
            disabled={isDisabled}
          />

          {valueType === "VALUE_COST" && (
            <TextInput
              name="cost"
              rules={{
                required: "Cost is required",
                validate: (cost) => {
                  return Number(cost) > (Number(value) || 0)
                    ? "Cost should be less than value"
                    : true;
                },
              }}
              label="Cost"
              placeholder="Enter Cost"
              value={cost ? `$${cost}` : ""}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\$/g, "");
                setValue("cost", +inputValue);
                methods.trigger("cost");
              }}
              disabled={isDisabled}
            />
          )}
        </div>

        {eligibilityCriteriaFields.map((field, index) => {
          const selectedEligibility =
            eligibilityCriteriaData?.result?.data.find(
              (x) =>
                x._id ===
                eligibilityCriterias?.[index]?.eligibilityCriteria?.value
            );

          const valueTypeEligibility = DynamicMultiSelectKeys.includes(
            selectedEligibility?.key!
          )
            ? "DYNAMIC_MULTI_SELECT"
            : selectedEligibility?.valueType;
          const eligibilityCriteriaOptions =
            selectedEligibility?.options?.map((x) => ({
              label: x.label,
              value: x.value,
            })) || [];

          return (
            <div key={field.id} className="grid grid-cols-3 gap-5 mt-5 ">
              <Select
                label={`Eligibility Criteria ${index + 1}`}
                name={`eligibilityCriterias.${index}.eligibilityCriteria`}
                rules={{
                  required: "Eligibility Criteria is required",
                  validate: (value) =>
                    value.value !== "" || "Eligibility Criteria is required",
                }}
                placeholder={`Select Eligibility Criteria ${index + 1}`}
                options={eligibilityOptions.filter(
                  (item) =>
                    ![
                      ...(index === 0 ? [] : disableEligibilityFieldsAddOn),
                      ...eligibilityCriterias.map(
                        (item) => item.eligibilityCriteria?.label
                      ),
                    ].includes(item.label)
                )}
                // value={eligibilityCriterias?.[0] || {}}
                onChange={(data) => {
                  if (disableEligibilityFieldsAddOn.includes(data.label)) {
                    setValue("eligibilityCriterias", []);
                  }
                  setValue(
                    `eligibilityCriterias.${index}.eligibilityCriteria`,
                    data
                  );
                  setValue(`eligibilityCriterias.${index}.data`, "");
                  setdynamicOptionsReset(true);
                }}
                isDisabled={isDisabled}
              />
              <div className="col-start-2 -col-end-1 flex gap-4 [&>div]:flex-grow">
                {valueTypeEligibility === "TEXT" && (
                  <TextInput
                    name={`eligibilityCriterias.${index}.data`}
                    rules={{
                      required: "Shashi Cash & Active Reservation is required",
                    }}
                    label="Select Values"
                    placeholder={`Enter ${selectedEligibility?.name}`}
                    disabled={isDisabled}
                  />
                )}

                {valueTypeEligibility === "DYNAMIC_MULTI_SELECT" && (
                  <DynamicMultiSelect
                    name={`eligibilityCriterias.${index}.data`}
                    rules={{
                      required: "Reservation Number is required",
                    }}
                    isMulti
                    label="Select Values"
                    placeholder={
                      DynamicMultiSelectKeys.includes(selectedEligibility?.key!)
                        ? `Enter ${selectedEligibility?.name}`
                        : `Select ${selectedEligibility?.name}`
                    }
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    checkboxOptions={true}
                    wrapperComponentStyle="col-start-2 -col-end-1 min-w-[50%] w-fit"
                    dynamicOptionsReset={dynamicOptionsReset}
                    handleDynamicOptionsReset={handleDynamicOptionsReset}
                    isDisabled={isDisabled}
                  />
                )}

                {valueTypeEligibility === "SINGLE_SELECT" && (
                  <Select
                    name={`eligibilityCriterias.${index}.data`}
                    rules={{ required: "Value is required" }}
                    label="Select Values"
                    placeholder={`Select ${selectedEligibility?.name}`}
                    options={eligibilityCriteriaOptions}
                    isDisabled={isDisabled}
                  />
                )}

                {valueTypeEligibility === "MULTI_SELECT" && (
                  <Select
                    name={`eligibilityCriterias.${index}.data`}
                    rules={{
                      required: "Shashi Cash & Active Reservation is required",
                    }}
                    isMulti
                    label="Select Values"
                    placeholder={`Select ${selectedEligibility?.name}`}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    checkboxOptions={true}
                    wrapperComponentStyle="col-start-2 -col-end-1 min-w-[50%] w-fit"
                    options={eligibilityCriteriaOptions}
                    isDisabled={isDisabled}
                  />
                )}
                {!isDisabled && (
                  <>
                    {index === 0 ? (
                      !!valueTypeEligibility &&
                      !disableEligibilityFieldsAddOn.includes(
                        selectedEligibility?.name!
                      ) ? (
                        <AddEligibilitySvg
                          className="mt-6 cursor-pointer"
                          onClick={eligibilityCriteriaAppendField}
                        />
                      ) : null
                    ) : (
                      <RemoveEligibilitySvg
                        className="mt-6"
                        onClick={() => eligibilityCriteriarRemoveField(index)}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
        <div className="grid grid-cols-3 gap-5 mt-5">
          <Select
            name="businessReason"
            rules={{ required: "Business Reason is required" }}
            label="Business Reason"
            placeholder="Select Business Reason"
            options={businessReasonOptions}
            isDisabled={isDisabled}
          />
        </div>

        <div className="mt-9">
          <h3 className="text-[20px] font-bold leading-[26.4px] text-[#1E2F65]">
            Offer Details
          </h3>

          <div className="grid grid-cols-3 gap-5 mt-6.5">
            <Select
              name="redemptionLocation"
              rules={{
                required: !isEditOffer
                  ? "Redemption Location is required"
                  : false,
              }}
              label="Redemption Location"
              placeholder="Select Redemption Location"
              options={redemptionLocationOptions}
              isDisabled={isDisabled}
            />
          </div>

          {!hideDatesFor.includes(
            eligibilityCriterias[0]?.eligibilityCriteria?.label as string
          ) &&
          !(
            isEditOffer &&
            !defaultValues.startDate &&
            !defaultValues.endDate
          ) ? (
            <>
              <div className="grid grid-cols-3 mt-6.5 gap-5">
                <DatePicker
                  name="startDate"
                  rules={{ required: "Start Date is required" }}
                  type="date"
                  label="Start Date"
                  placeholder="Oct 09, 2024"
                  min={new Date().toLocaleString("sv").split(" ")[0]}
                  disabled={isEditOffer && !isDraftEditOffer}
                />
                <DatePicker
                  name="endDate"
                  rules={{ required: "End Date is required" }}
                  type="date"
                  label="End Date"
                  placeholder="Nov 09, 2024"
                  min={startDate}
                  disabled={isEditOffer && !isDraftEditOffer}
                />
              </div>
              <div className="grid grid-cols-3 mt-6.5 gap-5">
                <TimeInput
                  name="startTime"
                  rules={{ required: "Start Time is required" }}
                  type="time"
                  label="Offer Start Time"
                  placeholder="03: 00 PM"
                  disabled={isEditOffer && !isDraftEditOffer}
                  // value={startTime || ""}
                  // onChange={(e) => setValue("startTime", e.target.value)}
                />
                <TimeInput
                  name="endTime"
                  rules={{
                    required: "End Time is required",
                    validate: validateEndTime(startDate, endDate, startTime),
                  }}
                  type="time"
                  label="Offer End Time"
                  placeholder="03: 00 PM"
                  disabled={isEditOffer && !isDraftEditOffer}
                  // value={endTime || ""}
                  // onChange={(e) => console.log(startTime , e.target.value , startDate === endDate , parseInt(startTime.split(':')[0]))}
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="mt-7.5">
          <h3 className="text-[20px] font-bold leading-[26.4px] text-[#1E2F65]">
            Setup Offers on TV
          </h3>
          {!hideDatesFor.includes(
            eligibilityCriterias[0]?.eligibilityCriteria?.label as string
          ) &&
          !(
            isEditOffer &&
            !defaultValues.tvSetup.startDate &&
            !defaultValues.tvSetup.endDate
          ) ? (
            <>
              <div className="grid grid-cols-3 mt-6.5 gap-5">
                <DatePicker
                  name="tvSetup.startDate"
                  rules={{ required: "start Date is required" }}
                  type="date"
                  label="Display Start Date"
                  placeholder="03: 00 PM"
                  min={new Date().toLocaleString("sv").split(" ")[0]}
                  disabled={isEditOffer}
                />
                <DatePicker
                  name="tvSetup.endDate"
                  rules={{ required: "End Date is required" }}
                  type="date"
                  label="Display End Date"
                  placeholder="06: 00 PM"
                  min={tvSetup?.startDate}
                  disabled={isEditOffer}
                />
              </div>
              <div className="grid grid-cols-3 mt-6.5 gap-5">
                <TimeInput
                  name="tvSetup.startTime"
                  rules={{ required: "Start Time is required" }}
                  type="time"
                  label="Display Offer Start Time"
                  placeholder="03: 00 PM"
                  // value={tvSetup?.startTime || ""}
                  // onChange={(e) => setValue("tvSetup.startTime", e.target.value)}
                  disabled={isEditOffer}
                />

                <TimeInput
                  name="tvSetup.endTime"
                  rules={{
                    required: "End Time is required",
                    validate: validateEndTime(
                      tvSetup?.startDate,
                      tvSetup?.endDate,
                      tvSetup?.startTime
                    ),
                  }}
                  type="time"
                  label="Display Offer End Time"
                  placeholder="06: 00 PM"
                  // value={tvSetup?.endTime || ""}
                  // onChange={(e) => setValue("tvSetup.endTime", e.target.value)}
                  disabled={isEditOffer}
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="mt-7">
          <div className="flex items-center gap-2.5 mt-5">
            <FileInput
              id="tv-setup-img"
              name="tvSetup.image"
              rules={{ required: "Image is required" }}
              label="Offer Image"
              type="file"
              accept="image/*"
              className="block w-[240px] text-[10px] leading-[18px] text-[#666672] border border-[#D9D9D9] rounded cursor-pointer focus:outline-none file:bg-[#F41095] file:box-border file:p-2 file:text-[10px] file:leading-[18px] file:outline-none file:border-none file:border-r file:border-[#D9D9D9] file:text-white"
              onChange={handleFileChange}
              onClear={handleImageClear}
            />
          </div>

          {tvSetup.image ? (
            <div className="relative my-4">
              <img
                src={
                  typeof tvSetup.image === "string"
                    ? tvSetup.image
                    : URL.createObjectURL(tvSetup.image)
                }
                alt="Cropped"
                height={"auto"}
                width={"229px"}
                className="mx-auto"
              />
            </div>
          ) : null}

          {imageFile && (
            <ImageCropModal
              isOpen={isCropModalOpen}
              image={imageFile}
              onClose={() => setIsCropModalOpen(false)}
              onCropComplete={handleCropComplete}
            />
          )}
        </div>

        <div className="flex items-center justify-end gap-4 mt-10">
          {!isEditOffer && (
            <Button
              className="w-32"
              onClick={saveAsDraft}
              loading={addDraftOfferLoading || updateDraftOfferLoading}
            >
              {isDraftEditOffer ? "Update draft" : "Save as draft"}
            </Button>
          )}
          <DiscardSaveButtons
            onDiscard={() => {
              setValue("tvSetup.image", null);
              const fileInput = document.getElementById(id || "tv-setup-img");
              if (fileInput) {
                (fileInput as HTMLInputElement).value = "";
              }
              if (isEditOffer) {
                setValue("tvSetup.image", null);
              }
              handleImageClear();
              setIsCropModalOpen(false);
              clearValues();
            }}
            onSave={handleSubmit(onSubmit)}
            loading={loading || updateLoading}
            saveBtnOptions={{
              name: isDraftEditOffer ? "Activate Offer" : "Save",
              className: isDraftEditOffer ? "w-fit" : "",
            }}
          />
        </div>
      </div>
    </FormProvider>
  );
};

export default CreateOffer;
